
import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// import { Observable } from 'rxjs';
// import { catchError } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { Router, ActivatedRoute } from '@angular/router';
// import * as CryptoJS from 'crypto-js';

@Injectable({
    providedIn: 'root'
})

export class UserService {

    // pidetään yllä tietoa onko kirjautuminen voimassa
    public authStatus = 0;

    // pidetään tietoa kirjautuneesta käyttäjästä
    public currUser = {
        id: 0,
        uname: '',
        fullname: '',
        email: '',
        puhelin: '',
        scope: 0,
        etusivu: 0,
    };

    //  tässä vähän toistoa mutta pidetään nyt vähän aikaa erillään varsinaisia site-tietoja
    public site = {
        name: '????',
        etusivu: 0,
    };

    // pidetään kirjaa dokumenttien scroll-positioista
    private lastScroll = new Map();

    public _handler = new Subject<any>();
    // huu$: Observable<string> = this._handler.asObservable();

    // ----------------------------

    constructor(
        private http: HttpClient,
        private router: Router,
    ) {
        this.authStatus = 0;
        // haetaan serveriltä tiedot mikäli käyttäjä on kirjautuneena jo valmiiksi
        // (tätä tarvitaan ainakin silloin jos tehdään selaimen refresh)
        this.getUser('init');
    }

    public isAuth(args: any) {
        // console.log('is-auth-fired...');
        if (this.authStatus) {
            return true;
        } else {
            this.router.navigateByUrl('/login');
        }
    }

    // tämä tehdään ainakin sovelluksen alussa
    public getLoginref(args: any) {
        const _url = '/api/getLoginref';
        return this.http
            .post<any>(_url, args)
            .toPromise()
            .then(res => {
                console.log('get-loginref-done (http) :', res);
                this.authStatus = 0;
                if (res.success && res.site) {
                    this.site = res.site;
                }
                return res as any;
            })
            .catch(e => {
                console.log('check-auth-catch-error:', e);
            });
    }

    // tämä tehdään ainakin sovelluksen alussa ja myös jos tehdään selaimen Refresh
    // Todo: paluu "false" voisi ohjata suoraan Login-sivulle
    public getAuth(args: any) {
        const _url = '/api/getAuth';
        return this.http
            .post<any>(_url, args)
            .toPromise()
            .then(res => {
                console.log('get-auth-done (http) :', res);
                this.authStatus = (res.success) ? 1 : 0;
                return res as any;
            })
            .catch(e => {
                console.log('check-auth-catch-error:', e);
            });
    }

    // haetaan kirjautuneen käyttäjän tiedot palvelimelta
    public getUser(stage: any) {
        const _url = '/api/getUser';
        return this.http
            .get<any>(_url)
            .toPromise()
            .then(res => {
                console.log('get-user-http-done:', res);
                this.currUser = res.user || {};
                if (res.site) {  this.site = res.site; }
                return res as any;
            })
            .catch(e => {
                console.log('get-user-catch-error:', e);
            });
    }

    // validoidaan Login-ruudun tiedot
    public validateLogin(args: any) {
        const _url = '/api/validateLogin';
        return this.http
            .post<any>(_url, args)
            .toPromise()
            .then(res => {
                console.log('validate-login-http-done:', res);
                // this.authStatus = (res.success) ? 1 : 0;
                if (res.success) {
                    this.authStatus = 1;
                    this.currUser = res.user || {};
                }
                console.log('user-data', this.currUser);
                // this._handler.next();   // Todo: tarvitaanko tätä lainkaan
                // this._handler.next([]);
                return res as any;
            })
            .catch(e => {
                console.log('check-auth-catch-error:', e);
            });
    }

    // validoidaan Login-ruudun tiedot
    public updSelf(args: any) {
        const _url = '/api/updSelf';
        const _data = { args: args as any };
        return this.http
            .post<any>(_url, _data)
            .toPromise()
            .then(res => {
                // console.log('update-omasivu-http-done:', res);
                return res as any;
            })
            .catch(e => {
                console.log('upd-self-catch-error:', e);
            });
    }

    // validoidaan Login-ruudun tiedot
    public updPasswd(args: any) {
        const _url = '/api/updPasswd';
        const _data = { args: args as any };
        return this.http
            .post<any>(_url, _data)
            .toPromise()
            .then(res => {
                console.log('update-passwd-http-done:', res);
                return res as any;
            })
            .catch(e => {
                console.log('upd-self-catch-error:', e);
            });
    }

    public logout() {
        const _url = '/api/logout';
        return this.http
            .get<any>(_url)
            .toPromise()
            .then(res => {
                console.log('logout-done', res);
                this.authStatus = 0;
                return res as any;
            })
            // .then(data => data as any)
            .catch(e => {
                console.log('check-auth-catch-error:', e);
            });
    }

    // haetaan käyttäjän email-osoite salasanan palautuslinkkiä varten
    public getUserMailAddress(uname: string) {
        const _url = '/api/getUserMail';
        return this.http
            .post<any>(_url, { uname: uname as string })
            .toPromise()
            .then(res => {
                console.log('get-user-mail-done:', res);
                return res as any;
            })
            .catch(e => {
                console.log('get-user-catch-error:', e);
            });
    }

    // pyydetän serveriä lähettämään salasanan palautuslinkki
    public sendPasslinkRequest(args: any) {
        const _url = '/api/passlink';
        return this.http
            .post<any>(_url, args)
            .toPromise()
            .then(res => {
                console.log('passlink-done:', res);
                return res as any;
            })
            .catch(e => {
                console.log('passlink-catch-error:', e);
            });
    }

    public setScroll(docid: number, value: number) {
        // console.log('user-service-set:', docid, value);
        this.lastScroll.set(docid, value);
    }

    public getScroll(docid: number) {
        return this.lastScroll.get(docid) || 0;
    }

}

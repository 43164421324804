
import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Message } from 'primeng/api';

import { UserService } from '../services/user.service';
import * as CryptoJS from 'crypto-js';

@Component({
    selector: 'app-omasivu',
    templateUrl: './omasivu.component.html',
    styleUrls: ['./omasivu.component.styl']
})

export class OmasivuComponent implements OnInit {

    public showPasswdDlg = false;

    public oldPass: string;
    public newPass1: string;
    public newPass2: string;

    // public alert1: Message[] = [];
    public alert2: Message[] = [];

    constructor(
        public userService: UserService,
        private _location: Location
    ) { }

    ngOnInit() {
        // haetaan sis.kirjautuneen käyttäjän tiedot serveriltä
        console.log('omasivu-on-init-start');
        // this.alert1.length = 0;
        this.alert2.length = 0;
        this.userService.getUser('omasivu')
            .then(res => {
                console.log('omasivu-user-done:', this.userService.currUser);
            });
    }

    public okBtn() {
        this.userService.updSelf(this.userService.currUser)
            .then(res => {
                this._location.back();
            });
    }

    public avaaPassDlg() {
        this.alert2.length = 0;
        this.oldPass = null;
        this.newPass1 = null;
        this.newPass2 = null;
        this.showPasswdDlg = true;
    }

    public updatePasswd() {
        // console.log('updatePasswd:', this.newPass1, this.newPass2);
        this.alert2.length = 0;
        switch (true) {
            case (!this.oldPass) :
                this.alert2.push({ severity: 'warn', detail: 'Vanha salasana puuttuu!' });
                break;
            case (!this.newPass1 || this.newPass1.length < 5) :
                this.alert2.push({ severity: 'warn', detail: 'Uuden salasanan pituus min.5 merkkiä ! ' });
                break;
            case (this.newPass1 !== this.newPass2) :
                this.alert2.push({ severity: 'warn', detail: 'Uuden salasanan vahvistus puuttuu tai virheellinen!' });
                break;
        }
        if (this.alert2.length === 0) {
            const args = {
                oldPass: CryptoJS.SHA512(this.oldPass.trim()).toString(),
                newPass: CryptoJS.SHA512(this.newPass1.trim()).toString()
            };
            this.userService.updPasswd(args)
                .then(res => {
                    if (res.success && res.msg && res.msg.length === 0 ) {
                        this.showPasswdDlg = false;
                    } else {
                        this.alert2.push({ severity: 'warn', detail: res.msg[0] });
                    }
            });
        }
    }

    public cancelBtn() {
        this._location.back();
    }

}

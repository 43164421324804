
import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfirmationService } from 'primeng/api';
import { Message } from 'primeng/api';

// import { UserService } from '../services/user.service';
import * as CryptoJS from 'crypto-js';


@Component({
    selector: 'app-manage',
    templateUrl: './manage.component.html',
    styleUrls: ['./manage.component.styl']
})

export class ManageComponent implements OnInit {

    // kaikki käyttäjät
    public users: any = [];

    // otetaan aina yksi esim. muokkaukseen
    public curr = {
        uname: null,
        fullname: null,
        email: null,
        puhelin: null,
        reader: null,
        editor: null,
        manager: null,
    };

    public newPass1 = '??';
    public newPass2 = '??';

    public showInsertDlg = false;
    public showPassDlg = false;
    public showUpdateDlg = false;

    public alert2: Message[] = [];

    // listalta valitut rivit (array)
    public selectedRows: any[] = [];

    constructor(
        public confirmationService: ConfirmationService,
        private http: HttpClient,
        // public userService: UserService,
    ) { }

    ngOnInit() {
        this.getAll();
    }

    // ---

    private getAll() {
        const _url = '/api/getAllUsers';
        return this.http
            .get<any>(_url)
            .toPromise()
            .then(res => {
                // console.log('all-users-done (http) :', res);
                this.users = res.users || [];
                // return res as any;
            })
            .catch(e => {
                console.log('get-users-catch-error:', e);
            });
    }

    // Todo: tarvitaankohan tätä lainkaan
    rowSelect(idx: number) {
        const found = this.selectedRows.indexOf(idx);
        if (found >= 0) {
           this.selectedRows.splice( found, 1 );
        } else {
            this.selectedRows.push(idx);
        }
    }

    isRowSelected(idx: number) {
        return  (this.selectedRows.includes(idx)) ? 'fa-check-square-o' : 'fa-square-o';
    }

    // avataan ikkuna uuden käyttäjän lisäämiseen
    uusi() {
        this.alert2.length = 0;
        this.curr.uname = null;
        this.curr.fullname = null;
        this.curr.email = null;
        this.curr.puhelin = null;
        this.curr.reader = 1;
        this.curr.editor = 0;
        this.curr.manager = 0;
        this.showInsertDlg = true;
    }

    // avataan update-dialog
    public avaaUpdateDlg(i) {
        this.alert2.length = 0;
        this.curr = (i >= 0) ? this.users[i] : this.users[0];
        // console.log('curr:', this.curr);
        this.showUpdateDlg = true;
    }

    // käydään päivittämässä käyttäjän tiedot kantaan
    public updateUser() {
        this.showUpdateDlg = false;
        const _url = '/api/updUserDetails';
        const data = {
            uname: this.curr.uname,
            fullname: this.curr.fullname,
            email: this.curr.email,
            puhelin: this.curr.puhelin,
            reader: this.curr.reader,
            editor: this.curr.editor,
            manager: this.curr.manager,
        };
        return this.http
            .post(_url, data)
            .toPromise()
            .then(res => {
                // console.log('up-done (http) :', res);
                // return res as any;
            })
            .catch(e => {
                console.log('upd-user-catch-error:', e);
            });
    }

    // käydään lisäämässä uusi käyttäjä kantaan
    public InsertUser() {
        this.showInsertDlg = false;
        const _url = '/api/insUserDetails';
        const data = {
            uname: this.curr.uname,
            fullname: this.curr.fullname,
            email: this.curr.email,
            puhelin: this.curr.puhelin,
            reader: this.curr.reader,
            editor: this.curr.editor,
            manager: this.curr.manager,
        };
        return this.http
            .post(_url, data)
            .toPromise()
            .then(res => {
                // console.log('ins-done (http) :', res);
                // return res as any;
                this.getAll();
            })
            .catch(e => {
                console.log('ins-user-catch-error:', e);
            });
    }

    // ---
    // poistetaan käyttäjän tiedot
    public poista() {

        if (!this.selectedRows.length) {
            alert('Valitse poistettava käyttäjä ruksaamalla');
            return false;
        }

        const _uname = this.users[this.selectedRows[0]].uname ;
        const _msg = 'Poistetaan käyttäjä ' + _uname;

        this.confirmationService.confirm({
            header: 'Vahvista käyttäjän poistaminen',
            message:  _msg,
            icon: 'fa fa-exclamation',
            acceptLabel: 'Ok',
            rejectLabel: 'Cancel',
            rejectVisible: true,
            accept: () => {
                this.selectedRows.length = 0;
                const _url = '/api/delUserDetails';
                return this.http
                    .post(_url, { uname: _uname })
                    .toPromise()
                    .then(res => {
                        // console.log('del-done (http) :', res);
                        // return res as any;
                        this.getAll();
                    })
                    .catch(e => {
                        console.log('ins-user-catch-error:', e);
                    });
            },
            reject: () => {
                this.selectedRows.length = 0;
            },
        });
    }

    // avataan ikkuna uuden salasanan pakottamista varten
    public avaaPasswd() {

        this.alert2.length = 0;

        // console.log('rows:', this.selectedRows);
        if (!this.selectedRows.length) {
            alert('Valitse käyttäjä ruksaamalla');
            return false;
        }

        this.curr.uname = this.users[this.selectedRows[0]].uname ;

        this.newPass1 = null;
        this.newPass2 = null;
        this.showPassDlg = true;

    }

    // pakotetaan uusi salasana käyttäjälle (tarkistus ja päivitys tietokantaan)
    public updatePasswd() {

        // console.log('pass=', this.newPass1, this.newPass2);

        this.alert2.length = 0;
        switch (true) {
            case (!this.newPass1 || this.newPass1.length < 5) :
                this.alert2.push({ severity: 'warn', detail: 'Uuden salasanan pituus min.5 merkkiä ! ' });
                break;
            case (this.newPass1 !== this.newPass2) :
                this.alert2.push({ severity: 'warn', detail: 'Uuden salasanan vahvistus puuttuu tai virheellinen!' });
                break;
        }
        if (this.alert2.length === 0) {
            const _args = {
                uname: this.curr.uname,
                newPass: CryptoJS.SHA512(this.newPass1.trim()).toString()
            };
            this.selectedRows.length = 0;
            this.showPassDlg = false;
            const _url = '/api/forceUserPasswd';
            return this.http
                .post(_url, _args)
                .toPromise()
                .then(res => {
                    // console.log('force-pass-done (http) :', res);
                    // return res as any;
                })
                .catch(e => {
                    console.log('ins-user-catch-error:', e);
                });

        }
    }

}

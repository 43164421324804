
import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';


@Component({
    selector: 'app-newcomm',
    templateUrl: './newcomm.html',
    styleUrls: ['./newcomm.styl']
})

export class NewCommComponent implements OnInit {

    constructor(
        private router: Router,
        private http: HttpClient,
    ) { }

    // private docUrl = 'http://localhost:9800';
    public clist = [];


    ngOnInit() {
        this.getData('init');
    }

    getData(args: any) {
        // console.log('newcomm-get-data-start:');
        // const _haku = this.docUrl + '/getNewComments';
        const _haku = '/api/getNewComments';
        return this.http
                .get<any>(_haku)
                .toPromise()
                .then(res => {
                    console.log(res);
                    this.clist = res.data;
                    return { success: true, count: this.clist.length };
                })
                .then(res => res);
    }

    routeToChild(id) {
        // Todo: pitäisi tarkistaa että args-parametrina tulee docid ja sellainen on olemassa
        // this.router.navigateByUrl('/sivu/' + (id || 0));
        this.router.navigateByUrl('/sivu/' + (id || 0) + '/komm');
    }

}


import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-pdf-viewer1',
    template: `
    <pdf-viewer [src]="fileName"
              [render-text]="true"
              style="display: block;"
              [original-size]="false"
              [autoresize]="true"
              >
    </pdf-viewer>
    `,
    styleUrls: ['./pdf-viewer1.component.styl']
})

export class PdfViewer1Component implements OnInit {

    @Input() fileName: any[];

    constructor() { }

    // private pdfSrc = 'https://cors-anywhere.herokuapp.com/https://www.kela.fi/documents/10192/3861304/EtkKela7001.pdf';
    // private pdfSrc = 'http://localhost:9800/showPdf/43';
    // private pdfSrc: any;

    ngOnInit() {
        // console.log('init-stage-filename:', this.fileName);
        // this.pdfSrc = 'http://localhost:9800/showPdf/42-' + this.fileName;
    }

}


import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

// import { Message } from 'primeng/api';
// import { MessageService } from 'primeng/api';

import { DocService } from '../services/doc.service';
import { LiiteService } from '../services/liite.service';

@Component({
    selector: 'app-uploader',
    templateUrl: './uploader.component.html',
    styleUrls: ['./uploader.component.styl']
})

export class UploaderComponent implements OnInit {

    public msg = [];

    // constructor(private messageService: MessageService) {}
    constructor(
        private router: Router,
        public docService: DocService,
        public liiteService: LiiteService
    ) {}

    // @Input() showDlg = false;
    // @Output() showDlg = new EventEmitter();
    // @Output() displayChange = new EventEmitter();

    // vlitetään tämän kautta dataa ylöspäin parent-komponentille
    @Output() msgToParent = new EventEmitter<any>();

    ngOnInit() { }

    // tämä ei ole toistaiseksi käytössä
    // OkBtn(evt) {
        // const element: HTMLElement = document.getElementById('huu') as HTMLElement;
        // console.log('huu:', element);
    // }

    annUpload(event, form) {

        // console.log('ann-uploader:', event, form);

        if (event.files.length === 0) {
            console.log('No file selected.');
            return;
        }

        // console.log('files:', event.files[0]);

        const file = event.files[0];
        const formData: any = new FormData();
        formData.append('file', file, file.name);
        // formData.append('site', 101);
        formData.append('id', this.docService.currId);
        // formData.append("name", this.form.get('name').value);
        // formData.append("avatar", this.form.get('avatar').value);

        this.liiteService.uploadLiite(formData)
            .then(res => {
                // console.log('upload-liite-done:', res);
                form.clear();
                this.msgToParent.emit({ task: 'done', data: res });
            });
    }

    cancelUpload(evt) {
        console.log('Todo: cancel');
        this.msgToParent.emit({ task: 'cancel' });
    }

}

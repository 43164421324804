
import { Component, OnInit, EventEmitter } from '@angular/core';
import { Form } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { TreeNode } from 'primeng/api';
// import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';

import { DocService } from '../services/doc.service';
import { TreeService } from '../services/tree.service';
import { ModService } from '../services/mod.service';

import tinymce from 'tinymce/tinymce';
// import 'tinymce/themes/silver';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/link';
import 'tinymce/plugins/table';
import 'tinymce/plugins/image';
import 'tinymce/plugins/autoresize';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/save';
// import 'tinymce/plugins/powerpaste';


// declare var tinymce: any;

@Component({
    selector: 'app-ann-editor',
    templateUrl: './ann-editor.component.html',
    // template: `
    // <editor [(ngModel)]="testHtml"
    //         [init]="{
    //             height: 700,
    //             plugins: 'link table',
    //             toolbar: 'undo redo | styleselect | bold italic | link image'
    //          }"
    // >
    // </editor>
    // `,
    // template: `
    //     <editor [(ngModel)]="html" [init]="tinyConfig" (init)="onInit()"></editor>
    // `,

    // tämä toimii aika hyvin
    // template: `<editor [(ngModel)]="html" [(init)]="tinyConfig"></editor>`,

    // kokeillaan formia (toimii ok)
    // template: `
    //     <form #f="ngForm" id="ann-editor" (ngSubmit)="onSubmit(f)">
    //         <p class="ann-ylapalkki">
    //             <button type="submit" class="btn btn-info ann-save">Talleta</button>
    //             <button type="reset" class="btn ann-cancel" (click)="cancelButton()">Peru</button>
    //             <i class="fa fa-edit"></i>
    //             <span class="ann-title">{{ currDoc.title }}</span>
    //         </p>
    //         <div class="form-group">
    //             <editor [(ngModel)]="html" name="tiny" [(init)]="tinyConfig"></editor>
    //         </div>
    //     </form>
    // `,
    styleUrls: ['./ann-editor.component.styl'],
    // providers: [MessageService]
})

export class AnnEditorComponent implements OnInit {

    // @ViewChild('tinymce') tinymce;

    private editor: any;

    public html: any;
    public currDoc: any;

    public showDlg1 = false;
    public LinkRefListener = new EventEmitter<any>();
    public linkRefReady: boolean;

    constructor(
        public router: Router,
        public route: ActivatedRoute,
        private messageService: MessageService,
        public docService: DocService,
        public treeService: TreeService,
        public modService: ModService,
    ) {
        // console.log('state:', this.router.getCurrentNavigation().extras.state);
        this.currDoc = this.router.getCurrentNavigation().extras.state || {};
        // console.log('editor-constructor-currDoc:', this.currDoc);
        // this.html = this.currDoc.html;
        // haetaan dokumentin sisältö (html) "docService" kautta ("edit-moodiin")
        const _request = {
            docid: this.currDoc.docid || 0,
            purpose: 'edit',
        };
        this.docService.getContent(_request)
            .then(res => {
                this.html = res;
            });
    }

    tinyConfig: any = {
        // theme: 'modern',
        // powerpaste advcode toc tinymcespellchecker a11ychecker mediaembed linkchecker help
        // plugins: 'print section table',
        plugins: 'autoresize table link anchor image lists emoticons hr',
        menubar: 'edit format insert lists table tools help',
        // toolbar: 'insertText copy undo redo formatselect ' +
        // toolbar: 'link2 formatselect ' +
        // seur.versio ennen muutoksia 2020-05-18
        // toolbar: 'removeformat | formatselect ' +
        //         ' | bold italic underline forecolor backcolor ' +
        //         ' | alignleft aligncenter alignright alignjustify ' +
        //         ' | numlist bullist outdent indent  emoticons | link2 anchor link unlink',
        toolbar: 'formatselect ' +
                ' | bold italic underline forecolor backcolor ' +
                ' | link2 anchor link unlink' +
                ' | numlist bullist outdent indent' +
                ' | alignleft aligncenter alignright alignjustify ',
        // height: 700,
        autoresize_on_init: true,
        autoresize_bottom_margin: 25,
        // min_height: 500,
        // paste_data_images: !0,
        paste_enable_default_filters: false,
        // convert_fonts_to_spans: true,
        // paste_word_valid_elements: "p,b,strong,i,em,u,h2,a[href],ol,ul,li,table,tr,td,th,tbody,thead",
        // paste_word_valid_elements: 'b,strong,i,em,h1,h2,u,p,ol,ul,li,a[href],span,color,font-size,font-color,font-family,mark'
        // + ',table,tr,td,th,tbody,thead',
        paste_retain_style_properties: 'all',
        verify_html: true,
        image_advtab: true,
        imagetools_toolbar: 'rotateleft rotateright | flipv fliph | editimage imageoptions',
        toolbar_sticky: true,
        // templates: [
        //     { title: 'Test template 1', content: 'Test 1' },
        //     { title: 'Test template 2', content: 'Test 2' }
        // ],
        // content_css: [
        //     '//fonts.googleapis.com/css?family=Lato:300,300i,400,400i',
        //     '//www.tinymce.com/css/codepen.min.css'
        // ],
        content_css: [
            '//fonts.googleapis.com/css?family=Lato:300,300i,300b,400,400i,400b,500,500i,500b',
            '/assets/ann-editor.custom.css'
        ],
        // Todo: tämä toimii ainakin periaatteessa, mutta täytyy säätää lisää
        // tosin tuo cancelButton() ei toimi oikein, mutta ehkä Dialog + tekstin lisäys voisi toimia
        setup: (editor) => {
            editor.ui.registry.addButton('link2', {
                text: 'Sis.linkki!',
                onAction: () => {
                    // Todo: alert ei välttämättä tarvita, vaan ohitetaan vaan koko linkin teko
                    if (!editor.selection || !editor.selection.getContent()) {
                        alert('Valitse teksti jonka taakse linkki muodostetaan');
                        return false;
                    }
                    // editor.insertContent('<strong>Hello World!</strong>');
                    this.showDlg1 = true;
                    // tässä ratkaisussa pidetään itse yllä tietoa siitä onko "subscribe" jo tehty vai ei.
                    // Tämä tuntuisi toimivan melko hyvin, joskin parempikin ratkaisu voisi olla olemassa.
                    if (!this.linkRefReady) {
                        this.LinkRefListener.subscribe(args => {
                            // console.log('jee eventin args=', args);
                            this.linkRefReady = true;
                            // console.log('editor-selection=', editor.selection.getContent());
                            const _link = '<a class="internal-link" href="#/sivu/'
                                        + ((args && args.node && args.node.id) ? args.node.id : 0)
                                        + '" target="_self" title="'
                                        + ((args && args.node && args.node.label) ? args.node.label : '')
                                        + '">' + editor.selection.getContent() + '</a>';
                            // console.log('link=', _link);
                            // editor.insertContent('<strong>' + _link + '</strong>');
                            editor.insertContent(_link);
                        });
                    }
                },
                // onInit ei tunnu toimivan lainkaan, pitää lukea dokumentaatiota mikä tuossa on väärin
                // onInit: () => {
                //     console.log('test-button-on-init');
                // },
            });
        }
    };

    // ---

    ngOnInit() {

        // kokeillaan vaikuttaako tämä jotenkin
        this.tinyConfig.autoresize_bottom_margin = 25;

        //  testataan tällä sitä että linkRef-subscribe tehdään vain kerran
        this.linkRefReady = false;

        // this.tinyConfig.editor.addButton('mybutton', {
        //     text: 'My button',
        //     icon: false,
        //     // onclick: function () {
        //     //     editor.insertContent('&nbsp;<b>It\'s my button!</b>&nbsp;');
        //     // }
        // });

        // Todo: tämä ei oikein toimi Angularissa ???????
        // "init" täytyy määritellä template:n sisällä

        tinymce.init({
            height: 600,
            // base: '/assets/tinymce',
            // suffix: '.min',
            // selector: '#' + this.elementId,
            plugins: ['link', 'paste', 'table'],
            // paste_as_text: true,    // lisätty 4.1.2021 [esa] => ei toimi oikein, laitettu saman tien kommentteihin
        });

        // tätä ei ilmeisesti tarvita
        // (nyt näyttää tree-olion samalla tavalla kuin se on vas.reunan valikossa)
        // Todo: tämän voisi kyllä ottaa erilleen vas.reunan puusta ja luoda jotenkin oma itsenäinen haku)
        // this.treeService.annMainTree.forEach( node => {
        //     this.expandRecursive(node, true);
        // });

    }

    // tämä hoitaa editorin  "Tallenna" buttonin toiminnallisuuden
    onSubmit(f: any) {
         // Todo: paljon vielä kesken
        // console.log('on-submit-currDoc:', this.currDoc);
        const _c = (f.form && f.form.value && f.form.value.tiny) ? f.form.value.tiny : 'eioo';
        // console.log(_c);
        this.docService.saveContent({ id: this.currDoc.docid, content: _c, temp: false })
            .then(res => {
                return this.modService.getData();
            })
            .then(res => {
                this.router.navigateByUrl('/sivu/' + this.currDoc.docid);
            });
    }

    saveOnly() {
        // console.log('html:', this.html);
        const mess = {
            key: 'annMsg1',
            severity: 'info',
            summary: 'Dokumentin talletus',
            detail: 'Sisältö talletettu ok.'
        };
        this.docService.saveContent({ id: this.currDoc.docid, content: this.html, temp: true })
            .then(res => {
                // console.log('save-only-done');
                this.messageService.add(mess);
            });
    }

    cancelButton() {
        console.log('peru-toiminto-painettu');
        this.router.navigateByUrl('/sivu/' + this.currDoc.docid);
    }

    // linkButton() {
    //     console.log('link-button-painettu');
    //     this.showDlg1 = true;
    // }

    // tällä vaan testattu dialog-ikkunan palautetta
    linkRefCallback(args: any) {
        console.log('test-dialog-paluu:', args);
        this.showDlg1 = false;
        this.LinkRefListener.next(args);
    }

     // ---
    private expandRecursive(node: TreeNode, isExpand: boolean) {
        node.expanded = isExpand;
        if (node.children) {
            node.children.forEach( childNode => {
                this.expandRecursive(childNode, isExpand);
            } );
        }
    }

}

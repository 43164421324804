
import { NgModule } from '@angular/core';

import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { ChangeDetectorRef } from '@angular/core';

import { ConfirmationService } from 'primeng/api';
import { AccordionModule } from 'primeng/accordion';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { CarouselModule } from 'primeng/carousel';
// import { ChartModule } from 'primeng/chart';
import { CheckboxModule } from 'primeng/checkbox';
// import { ChipsModule } from 'primeng/chips';
// import { CodeHighlighterModule } from 'primeng/codehighlighter';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ColorPickerModule } from 'primeng/colorpicker';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DataViewModule } from 'primeng/dataview';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { FieldsetModule } from 'primeng/fieldset';
import { FileUploadModule } from 'primeng/fileupload';
// import { GalleriaModule } from 'primeng/galleria';
// import { GrowlModule } from 'primeng/growl';
import { InplaceModule } from 'primeng/inplace';
import { InputMaskModule } from 'primeng/inputmask';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
// import { LightboxModule } from 'primeng/lightbox';
import { ListboxModule } from 'primeng/listbox';
import { MegaMenuModule } from 'primeng/megamenu';
import { MenuModule } from 'primeng/menu';
import { MenubarModule } from 'primeng/menubar';
// import { MessagesModule } from 'primeng/messages';
// import { MessageModule } from 'primeng/message';
import { MessageService } from 'primeng/api';
import { MultiSelectModule } from 'primeng/multiselect';
import { OrderListModule } from 'primeng/orderlist';
import { OrganizationChartModule } from 'primeng/organizationchart';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PaginatorModule } from 'primeng/paginator';
import { PanelModule } from 'primeng/panel';
import { PanelMenuModule } from 'primeng/panelmenu';
import { PasswordModule } from 'primeng/password';
import { PickListModule } from 'primeng/picklist';
import { ProgressBarModule } from 'primeng/progressbar';
import { RadioButtonModule } from 'primeng/radiobutton';
// import { RatingModule } from 'primeng/rating';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SlideMenuModule } from 'primeng/slidemenu';
import { SliderModule } from 'primeng/slider';
import { SpinnerModule } from 'primeng/spinner';
import { SplitButtonModule } from 'primeng/splitbutton';
// import { StepsModule } from 'primeng/steps';
import { TabMenuModule } from 'primeng/tabmenu';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { TerminalModule } from 'primeng/terminal';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { ToastModule } from 'primeng/toast';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { ToolbarModule } from 'primeng/toolbar';
import { TooltipModule } from 'primeng/tooltip';
import { TreeModule } from 'primeng/tree';
import { TreeTableModule } from 'primeng/treetable';
import { TreeDragDropService } from 'primeng/api';
import { VirtualScrollerModule } from 'primeng/virtualscroller';

import { AppComponent } from './app.component';
import { AppRoutes } from './app.routes';
// import { AppBreadcrumbComponent } from './app.breadcrumb.component';
// import { AppTopBarComponent } from './app.topbar.component';
// import { AppFooterComponent } from './app.footer.component';
// import { DashboardDemoComponent } from './demo/view/dashboarddemo.component';
// import { SampleDemoComponent } from './demo/view/sampledemo.component';
// import { DataDemoComponent } from './demo/view/datademo.component';
// import { PanelsDemoComponent } from './demo/view/panelsdemo.component';
// import { MenusDemoComponent } from './demo/view/menusdemo.component';
// import { MessagesDemoComponent } from './demo/view/messagesdemo.component';
// import { MiscDemoComponent } from './demo/view/miscdemo.component';
// import { EmptyDemoComponent } from './demo/view/emptydemo.component';
// import { ChartsDemoComponent } from './demo/view/chartsdemo.component';
// import { DocumentationComponent } from './demo/view/documentation.component';

// import { CarService } from './demo/service/carservice';
// import { CountryService } from './demo/service/countryservice';
// import { EventService } from './demo/service/eventservice';
// import { NodeService } from './demo/service/nodeservice';
import { BreadcrumbService } from './services/breadcrumb.service';
import { UserService } from './services/user.service';
import { TreeService } from './services/tree.service';
import { ModService } from './services/mod.service';
import { DocService } from './services/doc.service';
import { KommenttiService } from './services/kommentti.service';
import { WindowService } from './services/window.service';

// tämä on yksinkertaisempi versio
import { PdfViewerModule } from 'ng2-pdf-viewer';
// ja toinen jossa enemmän toimintoja (pääsääntöisesti käytössä)
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';

// kirjasto jolla saadaan drag-drop-toiminnot toteutettua
import { NgxSmoothDnDModule } from 'ngx-smooth-dnd';

// kirjasto tulostukseen (ei käytössä toistaiseksi, kokeiltu vaan)
// import { NgxPrintModule } from 'ngx-print';

// sovelluksen omia komponentteja
import { HakuComponent } from './haku/haku.component';
import { LeftbarComponent } from './leftbar/leftbar.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { EtusivuComponent } from './etusivu/etusivu.component';
import { RightbarComponent } from './rightbar/rightbar.component';
import { TopbarComponent } from './topbar/topbar.component';
import { FooterComponent } from './footer/footer.component';
import { TarklistaComponent } from './tarklista/tarklista.component';
import { SivuComponent } from './sivu/sivu.component';
import { SivucontainerComponent } from './sivu/sivucontainer.component';
import { UploaderComponent } from './uploader/uploader.component';
import { SafeHtmlPipe } from './safe-html.pipe';
import { KommentitComponent } from './kommentit/kommentit.component';
import { NewCommComponent } from './newcomm/newcomm.component';
import { PdfViewer1Component } from './pdf-viewer1/pdf-viewer1.component';
import { AnnEditorComponent } from './ann-editor/ann-editor.component';
import { EditorModule } from '@tinymce/tinymce-angular';
import { Pdfviewer2Component } from './pdfviewer2/pdfviewer2.component';
import { ReadersComponent } from './readers/readers.component';
import { NewdocComponent } from './newdoc/newdoc.component';
import { RemovedocComponent } from './removedoc/removedoc.component';
import { MetatiedotComponent } from './metatiedot/metatiedot.component';
import { LoginComponent } from './login/login.component';
import { Page404Component } from './page404/page404.component';
import { OmasivuComponent } from './omasivu/omasivu.component';
import { UsersComponent } from './users/users.component';
import { ManageComponent } from './manage/manage.component';
import { HistoryComponent } from './history/history.component';
// import { NgxTinymceModule } from 'ngx-tinymce';


@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        HttpClientModule,
        BrowserAnimationsModule,
        AccordionModule,
        AutoCompleteModule,
        BreadcrumbModule,
        ButtonModule,
        CalendarModule,
        CardModule,
        CarouselModule,
        // ChartModule,
        CheckboxModule,
        // ChipsModule,
        // CodeHighlighterModule,
        ConfirmDialogModule,
        ColorPickerModule,
        ContextMenuModule,
        DataViewModule,
        DialogModule,
        DropdownModule,
        FieldsetModule,
        FileUploadModule,
        // GalleriaModule,
        // GrowlModule,
        // LightboxModule,
        InplaceModule,
        InputMaskModule,
        InputSwitchModule,
        InputTextModule,
        InputTextareaModule,
        ListboxModule,
        MegaMenuModule,
        MenuModule,
        MenubarModule,
        // MessageModule,
        // MessagesModule,
        // MessageService,
        MultiSelectModule,
        OrderListModule,
        OrganizationChartModule,
        OverlayPanelModule,
        PaginatorModule,
        PanelModule,
        PanelMenuModule,
        PasswordModule,
        PickListModule,
        ProgressBarModule,
        RadioButtonModule,
        // RatingModule,
        ScrollPanelModule,
        SelectButtonModule,
        SlideMenuModule,
        SliderModule,
        SpinnerModule,
        SplitButtonModule,
        // StepsModule,
        TableModule,
        TabMenuModule,
        TabViewModule,
        TerminalModule,
        TieredMenuModule,
        ToastModule,
        ToggleButtonModule,
        ToolbarModule,
        TooltipModule,
        TreeModule,
        TreeTableModule,
        VirtualScrollerModule,
        AppRoutes,
        PdfViewerModule,
        NgxExtendedPdfViewerModule,
        NgxSmoothDnDModule,
        EditorModule,
        // NgxTinymceModule.forRoot({
        //     baseURL: '//cdnjs.cloudflare.com/ajax/libs/tinymce/4.9.0/',
        // })
    ],
    declarations: [
        AppComponent,
        // DataDemoComponent,
        // PanelsDemoComponent,
        // MenusDemoComponent,
        // MessagesDemoComponent,
        // MiscDemoComponent,
        // EmptyDemoComponent,
        // DocumentationComponent,
        HakuComponent,
        LeftbarComponent,
        BreadcrumbComponent,
        EtusivuComponent,
        RightbarComponent,
        TopbarComponent,
        FooterComponent,
        TarklistaComponent,
        SivuComponent,
        SivucontainerComponent,
        KommentitComponent,
        UploaderComponent,
        SafeHtmlPipe,
        NewCommComponent,
        PdfViewer1Component,
        AnnEditorComponent,
        Pdfviewer2Component,
        ReadersComponent,
        NewdocComponent,
        RemovedocComponent,
        MetatiedotComponent,
        LoginComponent,
        Page404Component,
        OmasivuComponent,
        UsersComponent,
        ManageComponent,
        HistoryComponent,
    ],
    providers: [
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        // ChangeDetectorRef,
        ConfirmationService,
        TreeDragDropService,
        MessageService,
        SivuComponent,
        UploaderComponent,
        ReadersComponent,
        HistoryComponent,
        KommentitComponent,
        BreadcrumbService,
        UserService,
        TreeService,
        ModService,
        DocService,
        KommenttiService,
        WindowService,
    ],
    bootstrap: [AppComponent]
})

export class AppModule {}

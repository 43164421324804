
import { Component, OnInit, OnDestroy } from '@angular/core';
// import { AppComponent } from './app.component';

import { BreadcrumbService } from '../services/breadcrumb.service';
import { Subscription } from 'rxjs';
import { MenuItem } from 'primeng/primeng';


@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.styl']
})

export class BreadcrumbComponent implements OnInit, OnDestroy {

  subscription: Subscription;
  items: MenuItem[];

  constructor(public bcService: BreadcrumbService) {
        this.subscription = bcService.itemsHandler.subscribe(response => {
            this.items = response;
        });
    }

  ngOnInit() { }

  ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

}

// @Component({
//     selector: 'app-breadcrumb',
//     templateUrl: './app.breadcrumb.component.pug'
// })



import { Injectable } from '@angular/core';
// import { Injectable, EventEmitter } from '@angular/core';
// import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})

export class LiiteService {

    public liiteNimi: string;

    // public _handler = new Subject<any>();

    constructor( private http: HttpClient ) { }

    // ---

    public getImage(args: any) {
        const _url = '/api/download/' + (args.docid || 'puuttuu');
        console.log('get-image:', _url);
        return this.http
            .get(_url, { responseType: 'blob'})
            .subscribe(res => {
                return res as any;
            });
    }

    public getFile(args: any) {
        const _haku = '/api/download/' + (args.docid || 'puuttuu');
        const _params = {
            id: args.docid || 0,
            filename: args.filename || 'default.data',
            filetype: args.filetype || null,
            filesuffix: args.filesuffix || null,
        };
        this.liiteNimi = _params.filename;
        return this.http
            .get(_haku, { responseType: 'blob'})
            // .get(_haku, { observe: 'response', responseType: 'blob' })
            .subscribe(res => {
                // window.open(window.URL.createObjectURL(res));
                this.talletaLiite(res);
            });
    }

    // ---
    // talletetaan liitetiedosto omalle levylle
    public talletaLiite(data) {
        // console.log('talleta-liite-alkaa:', data.length);
        const blob = new Blob([data], { type: 'application/octet-stream' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        // tässä kohtaa voidaan vaihtaa nimi halutuksi
        link.download = this.liiteNimi;
        // clicking the anchor element will download the file
        link.click();
    }

    // ladataan uusi tai muutettu liitetiedosto serverille
    public uploadLiite(args) {
        // console.log('liite-srv-upload-args:', args);
        const _url = '/api/fileUpload';
        return this.http
            .post<any>(_url, args)
            .toPromise()
            .then(res => {
                // console.log('liite-srv-upload-done:', res);
                // this._handler.next({ task: 'upload', data: res });
                return res as any;
            });
    }

}

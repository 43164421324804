
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
// import { DocService } from '../services/doc.service';

@Component({
    selector: 'app-removedoc',
    templateUrl: './removedoc.component.html',
    styleUrls: ['./removedoc.component.styl']
})

export class RemovedocComponent implements OnInit {

    // tuodaan parent:lta poistettavat id:t
    @Input() nodes: any;

    // vlitetään tämän kautta dataa ylöspäin parent-komponentille
    @Output() msgToParent = new EventEmitter<any>();

    constructor() { }

    ngOnInit() { }

    cancelBtn() {
        this.msgToParent.emit();
    }

    okBtn(event) {
        this.msgToParent.emit({ req: 'remove', data: this.nodes });
    }

}

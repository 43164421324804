
import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

@Component({
    selector: 'app-tarklista',
    templateUrl: './tarklista.component.html',
    styleUrls: ['./tarklista.component.styl']
})

export class TarklistaComponent implements OnInit {

    constructor(
        private router: Router,
        private http: HttpClient,
    ) { }

    public tlist = [];

    ngOnInit() {
        this.getData('init');
    }

    getData(args: any) {
        // console.log('tarklista-get-data-start:');
        const _haku = '/api/getTarkastusLista';
        return this.http
                .get<any>(_haku)
                .toPromise()
                .then(res => {
                    console.log(res);
                    this.tlist = res.data;
                    return { success: true, count: (this.tlist) ? this.tlist.length : 0 };
                })
                .then(res => res);
    }

    // muodostetaan class-attribuutti riippuen child-elementin tyypistä
    setFolderChildClass(type) {
        switch (type) {
            case 1:
                return 'fa-folder';
                break;
            case 2:
                return 'fa-edit';
                break;
            case 3:
                return 'fa-file-zip-o';
                break;
            case 4:
                return 'fa-file-image-o';
                break;
            case 5:
                return 'fa-external-link';
                break;
            default:
                return 'fa-cogs';
        }
    }

    // ---

    routeToChild(id) {
        // Todo: pitäisi tarkistaa että args-parametrina tulee docid
        this.router.navigateByUrl('/sivu/' + (id || 0));
    }

}

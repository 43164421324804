
import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// import { BehaviorSubject } from 'rxjs';

@Injectable()
export class ModService {

    // private muutokset = new BehaviorSubject('init-msg');
    // private uusimmat = this.muutokset.asObservable();
    public muutokset: any[];

    @Output() uudet: EventEmitter<any> = new EventEmitter();

    constructor(private http: HttpClient) { }

    getData(): any {
        const _haku = '/api/getModList';
        return this.http
                // .get<any>('data/muutokset02.json')
                .get<any>(_haku)
                .toPromise()
                .then(res => {
                    this.muutokset = res.data;
                    return res.data as any[];
                });
                // .then(res => res as any);
    }

    /*
    haeUusimmat() {
        console.log('Todo: saa poistaa! kokeiltu jotain');
        this.getData()
        .then(m => {
            console.log('hae-uusimmat-funktio:', m);
            m.push(this.testEntry);
            this.uudet.emit(m);
            return m as any;
        });
        // this.uudet.emit(this.getData());
        // this.uusimmat = 'uusi-msg';
        // this.muutokset.next(test);
    }
    */

}


import { Component, Input, ElementRef, ViewChild, OnInit } from '@angular/core';

import { KommenttiService } from '../services/kommentti.service';
import { DocService } from '../services/doc.service';

@Component({
  selector: 'app-kommentit',
  templateUrl: './kommentit.html',
  styleUrls: ['./kommentit.styl']
})

export class KommentitComponent implements OnInit {

    @Input() commList: any[];

    @ViewChild('kommteksti', { static: true }) private _inputRef: ElementRef;

    public currComm: any;
    public replyHeader = '';
    public replyTitle = '';
    public replyMsg = '';
    private replyParent = 0;
    private replyStage = 0;
    public showReplyWin = false;

    constructor(
        private commService: KommenttiService,
        private docService: DocService,
    ) {}

    ngOnInit() { }

    // ---
    // kun avataan dialog-ikkuna kommentin kirjoittamista varten (sekä uusi että vastaus),
    // niin tällä saadaan focus suoraan tekstikenttään
    public setFocus() {
        setTimeout(() => {
            this._inputRef.nativeElement.focus();
        }, 250);
    }

    // ---

    setCommClass(level) {

        switch (level) {
        case 0:
            return '';
            break;
        case 1:
            return 'ann-komm-level1';
            break;
        case 2:
            return 'ann-komm-level2';
            break;
        default:
            return 'ann-komm-level3';
        }

    }

    // ---

    avaaUusi() {
        this.currComm = {};
        this.replyHeader = 'Uusi kommentti';
        this.replyTitle = '';
        this.replyMsg = '';
        this.replyStage = 1;
        this.replyParent = 0;
        this.showReplyWin = true;
    }

    avaaReply(idx: number) {
        // console.log('vastaa-eventti', idx);
        this.currComm = this.commList[idx] || {};
        const t = (this.currComm && this.currComm.msg) ? this.currComm.msg : '';
        this.replyTitle = t.replace(/<p>/g, '').replace(/<\/p>/g).substring(0, 70);
        const _parent = this.currComm.id || 0;
        this.replyHeader = 'Vastaus kommenttiin: #' + _parent;
        this.replyMsg = '';
        this.replyParent = (this.currComm.seq) ? this.currComm.seq : this.currComm.id;
        this.replyStage = 2;
        this.showReplyWin = true;
    }

    avaaPoisto(idx: number) {
        this.currComm = this.commList[idx] || {};
        this.replyTitle = null;
        const t = (this.currComm && this.currComm.msg) ? this.currComm.msg : '';
        this.replyMsg  = t.replace(/<p>/g, '').replace(/<\/p>/g);
        this.replyHeader = 'Poista kommentti #' + this.currComm.id;
        this.replyParent = 0;
        this.replyStage = 9;
        this.showReplyWin = true;
    }

    manageReply() {
        this.showReplyWin = false;
        console.log('manage-reply:', this.currComm);
        const req = {
            stage: this.replyStage,
            comid: this.currComm.id || 0,
            docid: this.docService.currId || 0,
            parent: this.replyParent || null,
            seq: this.currComm.seq || null,
            text: (this.replyStage < 9) ? (this.replyMsg || null) : null,
        };
        this.commService.updateComm(req)
            .then(r => {
                // console.log('comm-upd-done', r);
                this.docService.notifyComm(r);
            });
    }

}

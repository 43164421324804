
import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
// import { catchError } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MenuItem } from 'primeng/primeng';

@Injectable({ providedIn: 'root' })

export class DocService {

    public currId: number;
    public currDoc: any = {};
    public currParent: any = {};

    public toolbar: MenuItem[];
    public toolbar2: MenuItem[];

    public readers: any[];
    public rdrCount: Observable<number>;
    public hist: any[];

    public _handler = new Subject<any>();
    // huu$: Observable<string> = this._handler.asObservable();

    constructor(private http: HttpClient) {

        this.readers = [];
        // this.rdrCount = 0;
        this.hist = [];

        // tämä toolbar käytössä niille joilla on Editointioikeudet tai pääkäyttäjäoikeudet
        this.toolbar = [
            {
                icon: 'fa fa-caret-down',
                styleClass: 'ann-sivu-scroll',
                command: (event) => {
                    this._handler.next({ req: 'scroll_end' });
                }
            },
            {
                icon: 'fa fa-caret-up',
                styleClass: 'ann-sivu-scroll',
                // style: { 'font-size': '24px', 'padding': '.2em .2em' },
                command: (event) => {
                    this._handler.next({ req: 'scroll_top' });
                }
            },
            {
                label: 'Muokkaa',
                icon: 'fa fa-edit',
                items: [
                    {
                        label: 'Muokkaa sisältöä',
                        icon: 'fa fa-file-text-o',
                        command: (evt) => {
                            this._handler.next({ req: 'muokkaa_content' });
                        }
                    },
                    {
                        label: 'Muokkaa dokumentin yleistietoja',
                        icon: 'fa fa-id-card-o',
                        command: (evt) => {
                            this._handler.next({ req: 'muokkaa_meta' });
                        }
                    },
                ]
            },
            { separator: true },
            {
                label: 'Toiminnot',
                icon: 'fa fa-cog',
                items: [
                    {
                        id: 'menu_tulosta_pdf',
                        label: 'Tulosta .pdf',
                        icon: 'fa fa-file-pdf-o',
                        command: (evt) => {
                            this._handler.next({ req: 'avaa_pdf-sivu' });
                        }
                    },
                    {
                        id: 'menu_tulosta_docx',
                        label: 'Lataa .docx',
                        icon: 'fa fa-file-pdf-o',
                        command: (evt) => {
                            // console.log('curr-doc', this.currDoc);
                            // console.log('DOCX-painettu-evt', evt);
                            if (this.currDoc.type===2) {
                                this._handler.next({ req: 'muod_docx-sivu' });
                            } else {
                                alert('Docx voidaan muodostaa vain "sivu" tyyppisestä dokumentista');
                            }
                        }
                    },
                    { separator: true },
                    {
                        label: 'Luo uusi',
                        icon: 'fa fa-file-o',
                        command: (evt) => {
                            this._handler.next({ req: 'luo_uusi' });
                        }
                    },
                    {
                        label: 'Kopioi/siirrä',
                        icon: 'fa fa-clone',
                        command: (evt) => {
                            this._handler.next({ req: 'copy_docs' });
                        }
                    },
                    {
                        label: 'Poista',
                        icon: 'fa fa-trash',
                        command: (evt) => {
                            this._handler.next({ req: 'poista_doc' });
                        }
                    },
                    { separator: true },
                    {
                        label: 'Merkkaa etusivuksi',
                        icon: 'fa fa-home',
                        command: (evt) => {
                            this._handler.next({ req: 'merkkaa_etusivu' });
                        }
                    },
                ]
            },
            {
                label: 'Tila',
                icon: 'fa fa-tag',
                // style: { color: '#9E1E28' },
                items: [
                    {
                        label: 'Valmis',
                        icon: 'fa fa-check-square-o',
                        styleClass: 'ann-valmis',
                        command: (evt) => {
                            this._handler.next({ req: 't_valmis' });
                        }
                    },
                    {
                        label: 'Hyväksyttävä',
                        icon: 'fa fa-thumbs-o-up',
                        styleClass: 'ann-tarkastus',
                        command: (evt) => {
                            this._handler.next({ req: 't_hyvaksyttava' });
                        }
                    },
                    {
                        label: 'Luonnos',
                        icon: 'fa fa-user-o',
                        styleClass: 'ann-luonnos',
                        command: (evt) => {
                            this._handler.next({ req: 't_luonnos' });
                        }
                    },
                    {
                        label: 'Rajoitettu',
                        icon: 'fa fa-user-secret',
                        styleClass: 'ann-rajoitettu',
                        command: (evt) => {
                            this._handler.next({ req: 't_rajoitettu' });
                        }
                    }
                ]
            },
            { separator: true },
            {
                label: 'Kommentit',
                icon: 'fa fa-comment-o',
                command: (event) => {
                    this._handler.next({ req: 'avaa_komm' });
                }
            },
            {
                label: 'Lukukuittaukset',
                icon: 'fa fa-hand-spock-o',
                command: (event) => {
                    this._handler.next({ req: 'avaa_rdr' });
                }
            },
            {
                label: 'Historia',
                icon: 'fa fa-tasks',
                command: (event) => {
                    this._handler.next({ req: 'avaa_hist' });
                }
            },

            // i.ann-left-btn.fa.fa-border.fa-caret-down(
            // 			(click)="expandAll()",
            // 			pTooltip="Avaa kaikki kansiot",
            // 			tooltipPosition="bottom")
            // 	i.ann-left-btn.fa.fa-border.fa-caret-up(
            // 			(click)="collapseAll()",
            // 			pTooltip="Sulje kaikki kansiot",
            // 			tooltipPosition="bottom")
        ];

        // toolbar2 käytetään käyttäjille joilla pelkkä Lukuoikeus (poistettu kaikki muutostoiminnot)
        this.toolbar2 = [
            {
                icon: 'fa fa-caret-down',
                styleClass: 'ann-sivu-scroll',
                command: (event) => {
                    this._handler.next({ req: 'scroll_end' });
                }
            },
            {
                icon: 'fa fa-caret-up',
                styleClass: 'ann-sivu-scroll',
                // style: { 'font-size': '24px', 'padding': '.2em .2em' },
                command: (event) => {
                    this._handler.next({ req: 'scroll_top' });
                }
            },
            { separator: true },
            {
                label: 'Toiminnot',
                icon: 'fa fa-cog',
                items: [
                    {
                        label: 'Tulosta (PDF)',
                        icon: 'fa fa-file-pdf-o',
                        command: (evt) => {
                            this._handler.next({ req: 'avaa_pdf-sivu' });
                        }
                    },
                ]
            },
            { separator: true },
            {
                label: 'Kommentit',
                icon: 'fa fa-comment-o',
                command: (event) => {
                    this._handler.next({ req: 'avaa_komm' });
                }
            },
            {
                label: 'Lukukuittaukset',
                icon: 'fa fa-hand-spock-o',
                command: (event) => {
                    this._handler.next({ req: 'avaa_rdr' });
                }
            },
            {
                label: 'Historia',
                icon: 'fa fa-tasks',
                command: (event) => {
                    this._handler.next({ req: 'avaa_hist' });
                }
            },

        ];

    }

    // getData() {
    //     return this.http
    //         .get<any>('data/test-doc-11.json')
    //         .subscribe((res) => {
    //             console.log('doc-service-get', res);
    //         });
    // }

    getDoc(args: any) {
        const _url = '/api/getDoc';
        // const _data = { docid: args || 0, children: true };
        return this.http
            .post<any>(_url, args)
            .toPromise()
            .then(res => {
                console.log('doc-service-get-done:', res);
                // this._handler.next();   // Todo: tarvitaanko tätä lainkaan
                if (res.success && res.doc && res.doc.id) {
                    this.currId = res.doc.id;
                    this.currDoc = res.doc;
                } else {
                    this.currId = 0;
                    this.currDoc = {};
                }
                // this._handler.next([]);
                return res as any;
            })
            .then(data => data as any);
    }

    getContent(args: any) {
        // const _haku = '/api/getDocContent' + '/' + args;
        const _url = '/api/getDocContent';
        return this.http
            .post<any>(_url, args)
            .toPromise()
            .then(res => {
                return res.html as string;
            })
            .then(data => data as string);
    }

    saveContent(args: any) {
        // console.log('save-content-args:', args);
        const _url = '/api/saveDocContent';
        const _data = { id: (args.id || 0), content: (args.content || null), temp: (args.temp || false) };
        return this.http
            .post<any>(_url, _data)
            .toPromise()
            .then(res => {
                // console.log('save-doc-content-done:', res);
                return res as any;
            });
    }

    // haetaan halutun dokumentin parent-doumentti
    // mikäli sama kuin aiemiin haettu currDoc, ja ollaan jo valmiiksi kansiotasolla (type=1)
    // niin ei tarvi hakea kannasta vaan palautetaan parentiksi sama kuin currDoc
    // (tarkoitus etsiä kansio lisättävälle uudelle dokumentille)
    getParent(id: number) {
        const _haku = '/api/getDocParent';
        if (this.currDoc.type === 1) {
            this.currParent = this.currDoc;
            return new Promise((resolve) => {
                resolve(this.currParent);
            });
        } else {
            return this.http
                .post<any>(_haku, { id: id as number })
                .toPromise()
                .then(res => {
                    // console.log('doc-service-parent-done:', res);
                    this.currParent = (res.success && res.parent) ? res.parent : {};
                    return res.parent as any;
                })
                .then(data => data as any);
        }
    }

    // getReaders(docid: any): Observable<any[]> {
    getReaders(docid: number) {
        const _url = '/api/getDocRdr';
        const _data = { docid: docid || 0};
        this.readers = [];
        return this.http
            .post<any>(_url, _data)
            // .pipe(res => res as any);
            // .pipe(res => this._handler.next(res);
            .toPromise()
            .then(res => {
                // console.log('readers-done:', res);
                this.readers = res.rdrList;
                this.rdrCount = res.rdrCount;
                this._handler.next(res);
                // return res as any;
                return res;
            });
    }

    // getReaders(docid: any): Observable<any[]> {
    getHistory(docid: number) {
        const _url = '/api/getDocHist';
        const _data = { docid: docid || 0};
        this.hist = [];
        return this.http
            .post<any>(_url, _data)
            .toPromise()
            .then(res => {
                // console.log('hist-done:', res);
                this.hist = res.hist;
                return res as any;
            });
    }

    getHistChange(idx: number) {
        const _url = '/api/getChange';
        const _data = {
            docid: this.currId,
            id: this.hist[idx].id || 0,
        };
        return this.http
            .post<any>(_url, _data)
            .toPromise()
            .then(res => {
                console.log('get-change-done:', res);
                return res as any;
            });
    }

    // ---
    // luodaan uusi dokumentti (kansio/sivu/tiedosto/linkki/kuva/tms)
    createNew(args: any): any {
        const _url = '/api/newDoc';
        const _req = {
            parent: (this.currParent) ? (this.currParent.id || 0) : 0,
            data: (args && args.data) ? args.data : {},
        };
        return this.http
            .post<any>(_url, _req)
            .toPromise()
            .then(res => {
                // console.log('new-doc-done:', res);
                // this._handler.next(res);
                return res as any;
            });
    }


    // ---
    // muutetaan dokumentin tilakoodia (state)
    updateState(docid: number, state: number): any {
        const _url = '/api/updState';
        const _data = { docid: (docid || 0), state: (state || 99), old: this.currDoc.state };
        return this.http
            .post<any>(_url, _data)
            .toPromise()
            .then(res => {
                // console.log('state-done:', res);
                // this._handler.next(res);
                return res as any;
            });
            // .then(data => data as any);
    }

    // ---
    // päivitetään kansion uusi järjestys tietokantaan
    updateSequence(docid: number, seq: any): any {
        const _url = '/api/updSequence';
        const _data = { docid: (docid || 0), seq: seq as any };
        return this.http
            .post<any>(_url, _data)
            .toPromise()
            .then(res => {
                // console.log('ssequence-done:', res);
                // this._handler.next(res);
                return res as any;
            });
    }

    // ---
    getEtusivu(args: any) {
        const _url = '/api/getEtusivu';
        return this.http
            .post<any>(_url, args)
            .toPromise()
            .then(res => {
                console.log('etusivu-done:', res);
                return res as any;
            });
    }

    // ---
    getMainFolders(args: any) {
        const _url = '/api/getMainFolders';
        return this.http
            .post<any>(_url, args)
            .toPromise()
            .then(res => {
                // console.log('main-folders-done:', res);
                return res as any;
            });
    }

    // ---
    // päivitetään current-doc etusivuksi (tietokantaan)
    merkkaaEtusivu(): any {
        const _url = '/api/merkkaaEtusivu';
        const _data = { docid: this.currId };
        return this.http
            .post<any>(_url, _data)
            .toPromise()
            .then(res => {
                return res as any;
            });
    }

    // ---
    // päivitetään lukukuittaus-tietoja
    updateKuittaus(docid: number, command: number): any {
        const _url = '/api/updReader';
        const _data = { docid: (docid || 0), command: (command || 0) };
        return this.http
            .post<any>(_url, _data)
            .toPromise()
            .then(res => {
                // console.log('kuittaus-done:', res);
                if (res.success) {
                    this.readers = res.rdrList;
                    this.rdrCount = res.rdrCount;
                }
                return res as any;
                // this._handler.next(res);
            });
    }

    notifyComm(args: any) {
        this._handler.next({ req: 'update_comm', data: (args.comments || []) });
    }

    // vanha versio joka poistaa yksittäisen
    // removeDoc(docid: number) {
    //     const _url = '/api/removeDoc';
    //     const _args = {
    //         id: docid,
    //         parent: (this.currDoc && this.currDoc.parent) ? this.currDoc.parent : 0,
    //     };
    //     return this.http
    //         .post<any>(_url, _args)
    //         .toPromise()
    //         .then(res => {
    //             // console.log('remove-doc-done:', res);
    //             return res as any;
    //         });
    // }

    // poistaa useita dokumentteja (=kansionäkymässä ruksatut rivit ts. nodes)
    removeDocs(nodes: any, parent: number) {
        const _url = '/api/removeDocs';
        const _args = {
            parent: parent as number,
            nodes: nodes as any
        };
        return this.http
            .post<any>(_url, _args)
            .toPromise()
            .then(res => {
                // console.log('remove-doc-done:', res);
                return res as any;
            });
    }

    updateFolderSeq(docid: number, seq: any): any {
        const _url = '/api/updFolderSeq';
        const _data = { id: docid || 0, seq: seq as any };
        return this.http
            .post<any>(_url, _data)
            .toPromise()
            .then(res => {
                console.log('sequence-done:', res);
                return res as any;
            });
    }

    // päivitetään dokumentin metatiedot serverille
    updateMeta(docid: number, data: any): any {
        const _url = '/api/updDocMeta';
        const _data = { id: docid || 0, data: data as any };
        return this.http
            .post<any>(_url, _data)
            .toPromise()
            .then(res => {
                // console.log('meta-ok:', res);
                return res as any;
            });
    }

    // ---
    // lähetetään backend-serverille pyyntö generoida docid perusteella .docx formaatissa oleva tiedosto
    // ja ladataan se omalle levylle (download)
    muodostaDocxVanha(docid: number): any {
        // console.log('muodosta-docx', this.currDoc);
        const _url = '/api/muodostaDocx/' + (docid||0);
        return this.http
            .get(_url, { responseType: 'blob'})
            .subscribe(res => {
                this.talletaDocx(res);
            });
    }

    // ---
    // lähetetään backend-serverille pyyntö generoida docid perusteella .docx formaatissa oleva tiedosto
    // ja ladataan se omalle levylle (download)
    muodostaDocx(docid: number): any {
        // console.log('muodosta-docx', this.currDoc);
        const _url = '/api/muodostaDocx/' + (docid||0);
        return this.http
            .get(_url, { responseType: 'blob'})
            .toPromise()
            .then(res => {
                // console.log('docx-api-done:', res);
                return res as any;
            });
    }

    // ---
    // talletetaan generoitu .docx-tiedosto omalle levylle
    // ---
    public talletaDocx(data) {
        const blob = new Blob([data], { type: 'application/octet-stream' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        // tässä kohtaa voidaan vaihtaa nimi halutuksi
        // link.download = 'test5566.docx';
        // link.download = this.currDoc.title.replace(/\s/g, '__') + '.docx';
        // nimi tulee suoraan/semmoisenaan sivun otsikosta (skandit mukaan semmoisenaan)
        link.download = this.currDoc.title + '.docx';
        // clicking the anchor element will download the file
        link.click();
    }

    // ---

    // getFolder(args: any) {
    //     const _haku = '/api/getDoc';
    //     const _data = { docid: args || 0, children: true };

    //     return this.http
    //         .post<any>(_haku, _data)
    //         .toPromise()
    //         .then(res => {
    //             console.log('folder-haku:', res)
    //             return res as any;
    //         })
    //         .then(data => data as any);
    // }

}


import { Component, OnInit, ElementRef, AfterContentInit } from '@angular/core';
import { Router } from '@angular/router';
// import { Router, ActivatedRoute } from '@angular/router';
// import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';

import { ConfirmationService } from 'primeng/api';
import { Message } from 'primeng/api';

import { UserService } from '../services/user.service';
import { TreeService } from '../services/tree.service';
import { ModService } from '../services/mod.service';
import * as CryptoJS from 'crypto-js';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.styl'],
    providers: [ConfirmationService],
})

export class LoginComponent implements OnInit {

    public currHref = null;

    public cred = {
        uname: null,
        pass: null,
        hostname: null,
    };

    public alert: Message[] = [];

    constructor(
        // private location: Location,
        private el: ElementRef,
        private router: Router,
        public confirmationService: ConfirmationService,
        public userService: UserService,
        public treeService: TreeService,
        public modService: ModService,
    ) { }

    ngOnInit() {

        this.userService.authStatus = 0;
        this.alert.length = 0;
        // asetetaan focus käyttäjätunnus-kenttään
        this.el.nativeElement.querySelector('#ann-uname').focus();

        // console.log('login url=', this.router.url);
        this.currHref = window.location.href || '???';
        this.userService.getLoginref({ href: window.location.href  })
            .then(res => {
                console.log('ok - serverin tiedot:', res);
                if (!res.success) {
                    this.router.navigateByUrl('unknown');
                } else {
                    this.cred.hostname = res.site.hostname || null;
                }
            });

    }


    // ---
    // validoidaan ruudun tiedot ja tehdään login mikäli ok
    public okBtn(evt) {

        console.log('validate:', this.cred, evt);
        this.alert.length = 0;  // tyhjennetään vanhat alert-ilmoitukset

        // tarkistetaan että tunnus ja salasana eivät saa puuttua
        if (!this.cred.uname || !this.cred.pass) {
            this.alert.push({ severity: 'warn', detail: 'Käyttäjätunnus tai salasana ei saa olla tyhjä!' });
            return false;
        }

        const passwdHash = CryptoJS.SHA512(this.cred.pass.trim()).toString();
        // console.log('hash:', passwdHash);
        this.userService.validateLogin({ uname: this.cred.uname, pass: passwdHash, hostname: this.cred.hostname })
            .then(res => {
                if (res.success) {
                    // haetaan vas. ja oik.reunan paneelien data mukaan
                    this.treeService.getData('login');
                    this.modService.getData();
                    this.router.navigateByUrl('etusivu/redirect');
                } else {
                    // Todo: validate-kutsun alert-ilmoitukset pitää näyttää ruudulle
                    res.alert.forEach(msg => {
                        this.alert.push({ severity: 'warn', detail: msg });
                    });
                }
            });

    }

    public sendPasslink() {

        this.alert.length = 0;  // tyhjennetään vanhat alert-ilmoitukset
        if (!this.cred.uname) {
            this.alert.push({ severity: 'warn', detail: 'Käyttäjätunnus täytyy antaa !' });
            return false;
        }
        let _email = null;

        this.userService.getUserMailAddress(this.cred.uname)

        .then(res => {
            _email = res.email;
            let _msg = 'Lähetetään salasanan palautuslinkki sähköpostiosoitteeseen : <br><br>'
                        + '<strong>' + _email + '</strong>';
            console.log('passlink-button');
            this.confirmationService.confirm({
                header: 'Vahvista salasanan palautus',
                message:  _msg,
                icon: 'fa fa-exclamation',
                acceptLabel: 'Ok',
                rejectLabel: 'Cancel',
                rejectVisible: true,
                accept: () => {
                    this.userService.sendPasslinkRequest({ uname: this.cred.uname, email: _email })
                    .then(r2 => {
                        console.log('passlink-done', r2);
                        _msg = 'Palautuslinkki lähetetty sähköpostiin : <strong>' + _email + '</strong><br><br>'
                                + '<strong>Linkki on voimassa 1 h ja vanhenee sen jälkeen automaattisesti</strong>';
                        this.confirmationService.confirm({
                            header: 'Linkki lähetetty sähköpostiisi',
                            message:  _msg,
                            icon: 'fa fa-paw',
                            acceptLabel: 'Ok',
                            rejectVisible: false,
                            accept: () => {},
                            reject: () => {},
                        });
                    });
                },
                reject: () => { }
            });
        });

    }

}

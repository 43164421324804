
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

@Component({
    selector: 'app-metatiedot',
    templateUrl: './metatiedot.component.html',
    styleUrls: ['./metatiedot.component.styl']
})

export class MetatiedotComponent implements OnInit {

    // tuodaan parent:lta curr.doc.olio mukaan
    @Input() doc: any;

    // väliaikaiset pmv-kentät (pitää siirtää "doc" olioon)
    // public startDate: Date;
    public endDate: Date;

    // vlitetään tämän kautta dataa ylöspäin parent-komponentille
    @Output() msgToParent = new EventEmitter<any>();

    constructor() { }

    ngOnInit() { }

    okBtn(event) {
        // console.log('meta-update:', this.doc);
        const _res = {
            title: this.doc.title,
            auth: this.doc.auth,
            descr: this.doc.descr,
            startdate: (this.doc.startdate)
                    ? this.doc.startdate.toISOString()
                    : null,
            starttime: (this.doc.starttime)
                    ? this.doc.starttime.getHours() + ':' + this.doc.starttime.getMinutes()
                    : null,
            enddate: (this.doc.enddate)
                    ? this.doc.enddate.toISOString()
                    : null,
            endtime: (this.doc.endtime)
                    ? this.doc.endtime.getHours() + ':' + this.doc.endtime.getMinutes()
                    : null,
        };
        this.msgToParent.emit({ task: 'update', data: _res });
    }

    cancelBtn() {
        this.msgToParent.emit({ task: 'cancel' });
    }
}


import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TreeNode } from 'primeng/api';

@Injectable()
export class TreeService {

    public annMainTree: TreeNode[] = [];

    public selectedNodes: TreeNode;
    public annTargetTree: TreeNode[] = [];

    // private files: TreeNode[];
    // private result: any;
    private laskuri1 = 0;

    private forward = true;
    // private imap = [];
    private imap2: TreeNode[] = [];

    constructor(private http: HttpClient) { }

    // getDataOld() {
    //     return this.http
    //             // .get<any>('data/testTree01.json')
    //             .get<any>('data/akaa.tree.json')
    //             .toPromise()
    //             .then(data => data);
    // }

    getData(target: any) {
        // console.log('tree-service-det-data-args:', args || '');
        const _haku = '/api/getDocTree';
        return this.http
                .get<any>(_haku)
                .toPromise()
                .then(res => {
                    // console.log(res);
                    this.laskuri1 = 0;
                    if (res.data && res.data.length > 0) {
                        for (let i = 0, len = res.data.length; i < len; i++) {
                            // let iter = this.traverseTree(res.data[i], 0);
                            this.traverseTree(res.data[i], 0, 0);
                        }
                    }
                    // varsinainen docTree löytyy "data" attribuutin alla, muu osa on metatietoa
                    this.annMainTree = res.data;
                    // palautetaan laskuri
                    return { success: true, nodeCount: this.laskuri1 };
                    // return res.data as any[];
                })
                .then(r2 => {
                    // console.log('tree-get-data-done:', r2);
                    return r2 as any;
                });
    }

    // ---

    getFolderTree() {
        // console.log('tree-service-det-data-args:', args || '');
        const _haku = '/api/getAllFolders';
        return this.http
                .get<any>(_haku)
                .toPromise()
                .then(res => {
                    console.log(res);
                    this.laskuri1 = 0;
                    if (res.data && res.data.length > 0) {
                        for (let i = 0, len = res.data.length; i < len; i++) {
                            this.traverseTree(res.data[i], 0, 0);
                        }
                    }
                    // lisätään vielä alkuu "Päätason kansio" jolloin voi valita myös sen
                    if (res.data) {
                        res.data.unshift({
                            id: 0,
                            parent: 0,
                            label: 'Pääkansio',
                            collapsedIcon: 'fa fa-home',
                        });
                    }
                    this.annTargetTree = res.data;
                    return { success: true };
                });
    }

    // ---

    traverseTree(node, depth, counter) {
        return new Promise((resolve, reject) => {
            // console.log('iter-curr=', curr)
            if (node.children && node.children.length) {
                const ch = node.children;
                for (let i = 0, len = ch.length; i < len; i++) {
                    this.traverseTree(ch[i], depth + 1, counter);
                }
            }
            // kaikille noodeille voitaisiin antaa joku attribuutti (esim. icon)
            // node.icon = 'fa fa-paw';
            if (node.data) {
                const type = node.data.type || 0;
                switch (type) {
                    // folder
                    case 1 :
                        node.collapsedIcon = 'fa fa-folder';
                        node.expandedIcon = 'fa fa-folder-open';
                        break;
                    // document
                    case 2 :
                        // node.icon = 'fa fa-file-text-o';
                        node.icon = 'fa fa-edit';
                        break;
                    // file
                    case 3 :
                        node.icon = 'fa fa-file-zip-o';
                        break;
                    // image
                    case 4 :
                        node.icon = 'fa fa-file-image-o';
                        break;
                    // link
                    case 5 :
                        node.icon = 'fa fa-external-link';
                        break;
                    // topic
                    case 6 :
                        node.icon = 'fa fa-tags';
                        break;
                    // large-plone-item
                    case 7 :
                        node.icon = 'fa fa-paw';
                        break;
                    // Todo: type image ja sille kuvake puuttuu
                    // ???
                    // oletus
                    default :
                        node.icon = 'fa fa-file-text-o';
                }
                // if (node.data.type && node.data.type === 1) {
                //         // node.collapsedIcon = (args === 'next') ? 'fa fa-paw' : 'fa fa-folder';
                //         node.collapsedIcon = 'fa fa-folder';
                //         // tree[i].collapsedIcon = 'fa fa-paw';
                //         node.expandedIcon = 'fa fa-cogs';
                // }
            }
            // poistetaan "parent_id" attribuutti, koska json on jo valmiina tree-muodossa
            // delete node.parent_id;
            // console.log('iter-node=', node.label, depth);
            counter++;
            // this.laskuri1++;
            return resolve({ done: true, counter: counter as number });
        });
    }

    // ---
    /*
    traverseTree(tree) {
        this.result = [];
        this.iter(tree, []);
        return 42;
    }

    iter(r, p) {
        const keys = Object.keys(r);
        if (keys.length) {
            return keys.forEach(x => this.iter(r[x], p.concat(x)));
        }
        this.result.push([p]);
    }
    */


    // testataan toimiiko Promise-mekanismi
    // (ei käytössä)
    asyncAction(tree: any, args: string) {
        return new Promise((resolve, reject) => {
        // const promise = new Promise((resolve, reject) => {
            // setTimeout(() => {
            //     console.log('Async done!');
            //     return resolve(data);
            // }, 200);
            let i = (tree) ? tree.length : 0;
            console.log('for-loop-len:', i);
            while (i--) {
                // console.log(tree.data[i]);
                // if (res.data[i].type && res.data[i].type === 1) {
                if (tree[i].type && tree[i].type === 1) {
                    tree[i].collapsedIcon = (args === 'next') ? 'fa fa-paw' : 'fa fa-folder';
                    // tree[i].collapsedIcon = 'fa fa-paw';
                    tree[i].expandedIcon = 'fa fa-cogs';
                }
                console.log('i=', i, tree.data[i]);
            }
            return resolve(tree);
        });
        // return promise;
    }

    async naviToDoc(docid: number) {
        // this.imap = [-1, -1, -1, -1, -1, -1, -1, -1, -1, -1];
        this.imap2 = [null, null, null, null, null, null, null, null, null, null];
        this.forward = true;
        this.selectedNodes = null;
        // this.selectedNodes = this.annMainTree[10].children[3].children[14];
        // this.annMainTree[10].children[3].expanded = true;
        // this.annMainTree[10].expanded = true;
        let idx: number;
        let len: number;
        for (idx = 0, len = this.annMainTree.length; idx < len; idx++) {
            await this.findDoc(docid, this.annMainTree[idx], 0, idx);
        }
        // mikäli haettu dokumentti on löytynyt, niin suljetaan ensin kaikki ja avataan tarvittavat nodet uudelleen
        if (this.imap2.length > 0) {
            this.collapseAll();
            for (idx = 0, len = this.imap2.length; idx < len; idx++) {
                if (this.imap2[idx]) {
                    // avataan node laittamalla sen "expanded" attribuutti arvoon true
                    this.imap2[idx].expanded = true;
                }
            }
        }
        // console.log('selected-nodes:', this.selectedNodes);
        // console.log('imap2:', this.imap2);
        // this.expandPath(this.annMainTree[this.imap[0]], 0, 0);

    }

    // ---
    // pyydetään palvelinta tekemään dokumenttien copy tai move
    public copymoveDocs(_args) {
        const _haku = '/api/copyOrMoveDocs';
        return this.http
            .post<any>(_haku, _args)
            .toPromise()
            .then(res => {
                console.log('copy-or-move-done:', res);
                return res as any;
            });
    }

    // ---
    // etsitään rekursiivisella haulla haluttu dokumentti "docid" avulla
    private findDoc(docid: number, curr: any, dept: number, index: number) {
        return new Promise((resolve, reject) => {
            if (this.forward && dept >= 0 && dept < 10) {
                // this.imap[dept] = index;
                this.imap2[dept] = curr;
            }
            if (curr.id && curr.id === docid) {
                this.selectedNodes = curr;
                // this.imap.splice(dept + 1);     // tällä poistetaan ylim.tasot lopusta
                this.imap2.splice(dept + 1);     // tällä poistetaan ylim.tasot lopusta
                this.forward = false;
                return resolve(true);
            }
            if (this.forward && curr.children && curr.children.length > 0) {
                const ch = curr.children;
                for (let i = 0, len = ch.length; i < len; i++) {
                    this.findDoc(docid, ch[i], dept + 1, i);
                }
            }
            return resolve(false);
        });
    }

    // Todo: tämä ei toimi ihan oikein, mutta jätetty malliksi jos jotain vastaavaa tarvetta
    // private expandPath(curr: TreeNode, dept: number, idx: number) {
    //     if (dept > 0 && this.imap[dept] === idx) {
    //         curr.expanded = true;
    //         console.log('löytää:', dept, idx);
    //         this.selectedNodes = curr;
    //     }
    //     if (curr.children) {
    //         const ch = curr.children;
    //         for (let i = 0, len = ch.length; i < len; i++) {
    //             this.expandPath(ch[i], dept + 1, i);
    //          }
    //     }
    // }

    // ---

    public collapseAll() {
        // this.files.forEach( node => {
        this.annMainTree.forEach( node => {
            this.expandRecursive(node, false);
        });
    }

    private expandRecursive(node: TreeNode, isExpand: boolean) {
        node.expanded = isExpand;
        if (node.children) {
            node.children.forEach( childNode => {
                this.expandRecursive(childNode, isExpand);
            } );
        }
    }

    // ---
    /*
    traverseTree(tree) {
        this.result = [];
        this.iter(tree, []);
        return 42;
    }

    iter(r, p) {
        const keys = Object.keys(r);
        if (keys.length) {
            return keys.forEach(x => this.iter(r[x], p.concat(x)));
        }
        this.result.push([p]);
    }
    */

    // ---
    /*
    private expandParentRecursive(node: TreeNode) {
        if ((node != null || node !== undefined) && (node.parent != null || node.parent !== undefined)) {
            node.parent.expanded = true;
            this.expandParentRecursive(node.parent);
        }
    }

    private expandRecursive(node: TreeNode, isExpand: boolean) {
        if (this.searchTerm !== undefined) {
            if (node.label.toLowerCase().includes(this.searchTerm.toLowerCase())) {
                this.expandParentRecursive(node);
                node.expanded = isExpand;
            } else {
                if (!node.label.toLowerCase().includes("root")) node.expanded = !isExpand;
            }
        }
        if (node.children) {
            node.children.forEach( childNode => {
                this.expandRecursive(childNode, isExpand);
            });
        }
    }
    */

}

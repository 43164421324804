
import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Router, ActivatedRoute } from '@angular/router';

// Todo: pitäisi oikeastaan käyyttä tätä serviceä eikä lukea itse http kutsulla
// import { LiiteService } from '../services/liite.service';

@Component({
    selector: 'app-pdfviewer2',
    templateUrl: './pdfviewer2.component.html',
    styleUrls: ['./pdfviewer2.component.styl']
})

export class Pdfviewer2Component implements OnInit {

    // private fileUrl = 'http://localhost:9800';
    // private docid: any;
    public currDoc: any;
    public pdfBlob: Blob;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private http: HttpClient,
        // private liiteService: LiiteService,
    ) {
        // constructorissa saadaan kiinni route:n mukana tulevat parametrit (extras)
        // (tässä tapauksessa tulee "state" muuttujan alla oma json-rakenne jossa on currentDoc )
        this.currDoc = this.router.getCurrentNavigation().extras.state || {};
    }

    ngOnInit() {
        // komponentin route:ssa täytyy olla mukana dokumentin id, jolla tiedot käydään hakemassa
        // (Huom. hoidettu nyt contructorissa vastaavasti mutta hieman toisella metodilla)
        // this.route.paramMap.subscribe(args => {
        //     console.log('paraMap-docid:', args);
        //     // haetaan ensin dokumentin perustiedot id:n avulla
        //     // this.docService.getDoc(args.get('id'))
        //     this.docid = args.get('id');
        // });

        // const _haku = this.fileUrl + '/download/' + (this.currDoc.docid || 0);
        const _haku = '/api/download/' + (this.currDoc.docid || 0);
        // console.log('pdf-viewer-2:', _haku);
        this.http
            .get(_haku, { responseType: 'blob'})
            // .get(_haku, { observe: 'response', responseType: 'blob' })
            .subscribe(res => {
                this.pdfBlob = res;
            });
    }

}


import { Component, OnInit } from '@angular/core';
// import { TreeNode } from 'primeng/api';

import { AppComponent} from '../app.component';
import { Router } from '@angular/router';
import { UserService } from '../services/user.service';
import { ModService } from '../services/mod.service';

@Component({
    selector: 'app-rightbar',
    templateUrl: './rightbar.component.html',
    styleUrls: ['./rightbar.component.styl']
})

export class RightbarComponent implements OnInit {

    cl: string;

  constructor(
        public app: AppComponent,
        private router: Router,
        public userService: UserService,
        public modSrv: ModService,
    ) { }

    ngOnInit() {
        // haetaan lista muuttuneista heti kun komponentti luodaan (on-init)
        this.modSrv.getData()
        .then((mod: any) => {
            // console.log('init-mod-data:', mod.data);
        });
    }

    // ---
    haeMuutokset(): void {
        this.modSrv.getData()
        .then((res: any) => {
            // console.log('modlist-done:', res);
        });
    }

    // ---
    haeRivinLuokka(state) {
        switch (state) {
        case 10:
            this.cl = 'ann-valmis';
            break;
        case 8:
            this.cl = 'ann-tark';
            break;
        case 5:
            this.cl = 'ann-luonnos';
            break;
        case 4:
            this.cl = 'ann-rajoitettu';
            break;
        case 1:
            this.cl = 'ann-private';
            break;
        default:
            this.cl = 'ann-luonnos';
        }
        return this.cl;
    }

    avaaSivu(args) {
        // Todo: pitäisi tarkistaa että args-parametrina tulee docid ja sellainen on olemassa
        // console.log('avaa sivu', args)
        this.router.navigateByUrl('/sivu/' + args);
    }

}

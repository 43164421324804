
import { Component, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
// import { debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

// import { MenuItem } from 'primeng/api';
import { TooltipModule } from 'primeng/tooltip';

import { AppComponent} from '../app.component';
import { UserService } from '../services/user.service';
// import { DocService } from '../services/doc.service';

@Component({
    selector: 'app-topbar',
    templateUrl: './topbar.component.html',
    styleUrls: ['./topbar.component.styl']
})

export class TopbarComponent implements AfterViewInit {

    hakuVisible = false;
    hakuteksti = null;

    docList: any = [];
    // menu1: MenuItem[];
    // valinta1: string;
    // valinta2: string;

    private _url = '/api/searchDocs';

    private hakusana = new Subject<string>();

    // @ViewChild("myInput") private _inputElement: ElementRef;
    @ViewChild('search', { static: true }) private _inputRef: ElementRef;


    // readonly hakutulos = this.hakusana.pipe(
    //   // tap(),
    //   debounceTime(200),
    //   distinctUntilChanged(),
    //   switchMap(args => this.blogService.hae(args)),
    //   tap(data => console.log('tap-args:', data.length))
    // );

    constructor(
        public app: AppComponent,
        private router: Router,
        private http: HttpClient,
        public userService: UserService,
        // public docService: DocService,
    ) { }

    ngAfterViewInit() { }

    // ---

    public setFocus() {
        setTimeout(() => {
            this._inputRef.nativeElement.focus();
        }, 250);
    }


    private avaaTarklista(evt) {
        this.router.navigateByUrl('/tarkastuslista');
    }

    private avaaNewComm(evt) {
        this.router.navigateByUrl('/uusimmatkommentit');
    }

    private omasivu(evt) {
        this.router.navigateByUrl('/omasivu');
    }

    private logout(evt) {
        this.userService.authStatus = 0;
        // Todo: voisi kutsua userService:n sopivaa funktiota joka tekee sitten route-ohjauksen + tarvttaessa muut asiat
        this.userService.logout()
            .then(res => {
                this.router.navigateByUrl('/login');
            });
    }

    private manage(evt) {
        this.router.navigateByUrl('/manage');
    }

    public avaaHaku() {
        // console.log('input:', this._inputRef);
        // Huom! nämä saatu toimimaan vain "onShow" eventissä aikaviiveen kanssa ???
        // this._inputRef.nativeElement.focus();
        // this._inputRef.nativeElement.click();
        this.hakuteksti = null;
        this.docList = [];
        this.hakuVisible = true;
    }

    public teeHaku(haku: string) {
        if (haku.length > 2) {
            // this.hakusana.next(args);
            this.http
                .post<any>(this._url, { args: haku })
                // .get(_haku, { observe: 'response', responseType: 'blob' })
                .subscribe(res => {
                    // console.log('löytyy pumaska:', res);
                    this.docList = res.docs || [];
                });
        }
    }


    public setRowClass(state) {
        const res = [];
        switch (state) {
            case 4:
                res.push('ann-rajoitettu');
                break;
            case 8:
                res.push('ann-tarkastus');
                break;
            case 10:
                res.push('ann-valmis');
                break;
            default:
                res.push('ann-luonnos');
        }
        return res;
    }

    public setRowType(type) {
        const res = [];
        switch (type) {
            case 1:
                res.push('fa-folder-open');
                break;
            case 2:
                res.push('fa-edit');
                break;
            case 3:
                res.push('fa-file-zip-o');
                break;
            case 4:
                res.push('fa-file-image-o');
                break;
            case 5:
                res.push('fa-external-link');
                break;
            default:
                res.push('fa-cogs');
        }
        return res;
    }

    public routeToChild(id) {
        this.hakuVisible = false;
        this.router.navigateByUrl('/sivu/' + (id || 0));
    }

}


import { Component, ViewChild, ViewEncapsulation, ElementRef, HostListener, AfterViewInit } from '@angular/core';

// import { ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
// import { DomSanitizer } from '@angular/platform-browser';

import { Router, ActivatedRoute } from '@angular/router';

// import { TreeTableModule, TreeTable } from 'primeng/treetable';
// import { TreeNode } from 'primeng/api';
import { MenuItem } from 'primeng/primeng';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmationService } from 'primeng/api';

// tätä tarvitaan drag-drop-toimintoihin
import { ContainerComponent, DraggableComponent, DropResult } from 'ngx-smooth-dnd';

// import { AppComponent } from '../app.component';
import { UserService } from '../services/user.service';
import { TreeService } from '../services/tree.service';
import { DocService } from '../services/doc.service';
import { KommenttiService } from '../services/kommentti.service';
import { LiiteService } from '../services/liite.service';
import { ModService } from '../services/mod.service';
import { KommentitComponent } from '../kommentit/kommentit.component';
import { ReadersComponent } from '../readers/readers.component';
import { HistoryComponent } from '../history/history.component';
import { NewdocComponent } from '../newdoc/newdoc.component';

@Component({
    selector: 'app-sivu',
    templateUrl: './sivu.component.html',
    // template: `
    // <div style="border: 1px solid violet; padding: 15px;">
    // <p #ploneRef> tpl-pohja-arvo </p>
    // </div>
    // `,
    styleUrls: ['./sivu.component.styl'],
    encapsulation: ViewEncapsulation.None,
})

export class SivuComponent implements AfterViewInit {

    // @Input() otsikko: string;

    @ViewChild('pRef', { static: false }) pRef: ElementRef;
    // @ViewChild('ploneRef', { static: false }) ploneRef: ElementRef;
    public ploneContent = '<p>Haetaan sivua ... odota ...</p>';

    @ViewChild(NewdocComponent, { static: false }) newdocRef: any;

    public visibleDialog1 = false;
    public visiblePdf = false;
    public showUploader = false;

    public showCreateDlg = false;
    public showMetaDlg = false;
    public showRemoveDlg = false;

    public showTargetDlg1 = false;
    public showTargetDlg2 = false;
    public sourceNodes = [];
    public sourceWithComm = false;
    public sourceWithRdr = false;
    public targetNode = {
        id: 0,
        title: null,
        type: 0,
        filetype: null,
    };

    public currTab = 0;

    // kansionäkymässä valitut rivit (array)
    public selectedRows: number[] = [];

    // tarvitaan tiedoston lataustoiminnossa
    fileUrl: any;

    // tämä pitää sisällään käsittelyssä olevan dokumentin [kaikki] tiedot
    document: any;

    public curr = {
        docid: 0,
        parent: 0,
        doctype: 0,
        state: 0,
        title: '??',
        descr: null,
        author: '-',
        names: '-',
        mstamp: null,
        mauth: null,
        startts: null,
        endts: null,
        remotelink: null,
        filename: '??',
        filetype: '??',
        filesuffix: null,
        filesize: 0,
        pdfFile: '??',
        children: [],
        comments: [],
        html: null,
    };

    // otetaan erilleen dokumentin metatiedot (ylläpitoa varten)
    public meta = {
        docid: 0,
        title: null,
        descr: null,
        auth: null,
        startdate: null,
        starttime: null,
        enddate: null,
        endtime: null,
    };


    // ---

    constructor(
        // private app: AppComponent,
        private router: Router,
        private route: ActivatedRoute,
        public confirmationService: ConfirmationService,
        private kommentit: KommentitComponent,
        private readers: ReadersComponent,
        private hist: HistoryComponent,
        public userService: UserService,
        public treeService: TreeService,
        // private toolbarService: ToolbarService,
        public docService: DocService,
        public commService: KommenttiService,
        public liiteService: LiiteService,
        public modService: ModService,
        // private cdRef: ChangeDetectorRef,
        // private sanitizer: DomSanitizer,
    ) {
        // console.log('constructor-start');
        // const blob = new Blob([this.ploneContent], { type: 'application/octet-stream' });
        // this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob));
        // this.pRef.nativeElement.innerHTML = 'DOM updated huu44 ';
        this.selectedRows = [];
    }


    ngAfterViewInit() {

        // console.log('sivu-component-view-init-route-params:', this.route.paramMap);
        // console.log('route-params:', this.route.snapshot.paramMap.get('fragment'));

        // Todo: tätä ei vielä uskaltanut ottaa käyttöön ilman testaamista
        // this.userService.getUser('sivu')
        //     .then(_u => {
        //         // console.log('user=', _u);
        //         if (!_u.user.scope || _u.user.scope < 5) {
        //             this.docService.toolbar[2].visible = false;
        //             this.docService.toolbar[4].visible = false;
        //             this.docService.toolbar[5].visible = false;
        //         }
        //     });

        // kuulostellaan docServiceltä tulevia eventtejä (menu-toiminnot)
        this.docService._handler.subscribe(args => {
            const _req = (args && args.req) ? args.req : 'req_unknown';
            switch (_req) {
                case 'muokkaa_content':
                    this.avaaMuokkaus();
                    break;
                case 'muokkaa_meta':
                    this.avaaMetatiedot();
                    break;
                case 'luo_uusi':
                    this.avaaNewDoc();
                    // this.docService.getParent(this.curr.docid)
                    // .then(r => {
                    //     this.showCreateDlg = true;
                    // });
                    break;
                case 'avaa_pdf-sivu':
                    this.avaaPdf();
                    break;
                case 'muod_docx-sivu':
                    this.avaaDocx();
                    break;
                case 'copy_docs':
                    this.copyDocs();
                    break;
                case 'poista_doc':
                    this.removeSelected();
                    break;
                case 'merkkaa_etusivu':
                    // this.docService.merkkaaEtusivu();
                    this.annMarkEtusivu();
                    break;
                case 't_valmis':
                    this.muutaTilakoodia(10);
                    break;
                case 't_hyvaksyttava':
                    this.muutaTilakoodia(8);
                    break;
                case 't_luonnos':
                    this.muutaTilakoodia(5);
                    break;
                case 't_rajoitettu':
                    this.muutaTilakoodia(4);
                    break;
                case 'avaa_komm':
                    this.currTab = 0;
                    document.querySelector('#ann-sivu-tabs').scrollIntoView({ behavior: 'smooth', block: 'center' });
                    break;
                case 'avaa_rdr':
                    this.currTab = 1;
                    this.docService.getReaders(this.curr.docid);
                    document.querySelector('#ann-sivu-tabs').scrollIntoView({ behavior: 'smooth', block: 'center' });
                    break;
                case 'avaa_hist':
                    this.currTab = 2;
                    this.docService.getHistory(this.curr.docid);
                    document.querySelector('#ann-sivu-tabs').scrollIntoView({ behavior: 'smooth', block: 'center' });
                    break;
                case 'update_comm':
                    // console.log('update_comm_event', args);
                    if (args && args.data) { this.curr.comments = args.data; }
                    break;
                case 'scroll_top':
                    document.querySelector('#ann-dokumentti').scrollIntoView({ behavior: 'smooth', block: 'start' });
                    break;
                case 'scroll_end':
                    document.querySelector('#ann-sivu-tabs').scrollIntoView({ behavior: 'smooth', block: 'center' });
                    break;
                default:
                    // console.log('tulee-jotain-muuta:', args);
                }
        });

        // komponentin route:ssa täytyy olla mukana dokumentin id, jolla tiedot käydään hakemassa
        this.route.paramMap.subscribe(args => {

            // console.log('sivu-component-init-view-paraMap:', args);
            // this.ploneRef.nativeElement.innerHTML = this.ploneContent + '<p>Lisätään docid.v.2: ' + args.get('id') + '</p>';
            // this.pRef.nativeElement.innerHTML = '<p>Lisätään docid.v.2: ' + args.get('id') + '</p>';

            // alustetaan  välilehtien index niin että avaa aina kommenttivälilehden kun haetaan uusi dokumentti
            this.currTab = 0;
            // Todo: tämä antaa virhettä mutta periaatteessa toimisi kyllä !!!
            // this.cdRef.detectChanges();

            // tyhjennetään myös vanhat ruksitukset (kansionäkymän vas.reunan ruksit)
            this.selectedRows = [];
            this.sourceNodes = [];

            // haetaan ensin dokumentin perustiedot id:n avulla
            const _request = {
                docid: args.get('id'),
                children: true,
            };
            // this.docService.getDoc(args.get('id'))
            this.docService.getDoc(_request)
                .then(res => {
                    // console.log('doc-json-done', res);
                    this.selectedRows = [];
                    // this.readers.newDoc();
                    this.document = (res && res.success && res.doc) ? res.doc : {};
                    this.curr.docid = Number(args.get('id'));   // Todo: tämä voisi kyllä tulla serveriltä paluusanomassa
                    this.curr.parent = this.document.parent || 0;
                    this.curr.doctype = this.document.type || 0;
                    this.curr.state = this.document.state || 5;
                    this.curr.title = this.document.title || '??';
                    this.curr.descr = this.document.descr || null;
                    this.curr.remotelink = this.document.remotelink || null;
                    this.curr.author = this.document.auth || '-';
                    this.curr.names = this.document.names || '-';
                    this.curr.mstamp = this.document.modts || null;
                    this.curr.mauth = this.document.modauth || null;    // näytetään lyhyt nimi (username)
                    this.curr.filename = this.document.filename || '- puuttuu -';
                    this.curr.filetype = this.document.filetype || '';
                    this.curr.filesize = this.document.filesize || 0;
                    this.curr.filesuffix = this.document.filesuffix || null;
                    this.curr.children = this.document.children || [];
                    this.curr.comments = this.document.comments || [];

                    // this.curr.mauth = this.document.modname || '';   // tämä on pitkä nimi modauth-kentästä
                    // päätellään filetype (=suffix)
                    if (!this.curr.filesuffix && this.curr.filename) {
                        this.curr.filesuffix = this.curr.filename.split('.').pop();
                    }
                    // irrotetaan ns. metatiedot ylläpitoa varten erilleen
                    // Todo: olisiko tälle joku parempi ratkaisu; esim. spread ...curr
                    this.meta.docid = this.curr.docid;
                    this.meta.title = this.curr.title;
                    this.meta.descr = this.curr.descr;
                    this.meta.auth = this.curr.author;
                    // dokumentin alkupvm
                    this.meta.startdate = (this.document.startts) ? new Date(this.document.startts) : null;
                    // dokumentin alkuaika
                    this.meta.starttime = (this.document.startts) ? new Date(this.document.startts) : null;
                    // dokumentin loppupvm
                    this.meta.enddate = (this.document.endts) ? new Date(this.document.endts) : null;
                    // dokumentin loppuaika
                    this.meta.endtime = (this.document.endts) ? new Date(this.document.endts) : null;

                    const fragm = this.route.snapshot.paramMap.get('fragment') || null;
                    if (fragm && fragm === 'komm') {
                        this.currTab = 0;
                        document.querySelector('#ann-sivu-tabs').scrollIntoView();
                    }

                    // this.curr.pdfFile = 'http://localhost:9800/showPdf/doc' + args.get('id');

                    // haetaan dokumentin sisältö (html) [ tarpeen vain type=2 eli sivudokumenteilla]
                    if (this.curr.doctype === 2) {
                        // return this.docService.getContent(args.get('id'));
                        return this.docService.getContent(_request);
                    } else {
                        return null;
                    }
                })
                .then(content => {
                    // console.log('doc-content-done', content);
                    this.curr.html = content;

                    // tehdään lisätoimia sen mukaan millainen dokumenttityyppi
                    // folder-tyypille haetaan kaikki "children" elementit
                    if ((this.curr.doctype === 1 && this.document.children)) {
                        // Todo: ei vielä mitää erikoista koska "children" on huomioitu jo aik.
                    }
                    // mikäli dokumentin tyyppi on 2 (sivu) niin renderöidään HTML ruudulle
                    if (this.curr.doctype === 2) {
                        this.pRef.nativeElement.innerHTML = content;
                    }

                    // avataan vas.reunan tree-elementistä oikeat kansiot (ja suljetaan muut)
                    this.treeService.naviToDoc(this.curr.docid);

                    // testii 23.5.2020
                    // this.scrollToPrev();

                });

        });
        // console.log('pref=', (this.pRef) ? this.pRef.nativeElement : 'eioo');
        // this.pRef.nativeElement.innerHTML = 'DOM updated huu44 ';

    }

    // ---

    downloadTiedostoliite(evt) {
        // console.log('download', evt);
        // this.liiteService.liiteNimi = 'huuhaanimi42.xlsx';
        // console.log('haetaan nimellä:', this.liiteService.liiteNimi);
        // const url = 'http://localhost:9800/file/test';
        // window.location.href='http://localhost:9800/file/test';
        // window.open(url);
        this.liiteService.getFile(this.curr);
    }

    // palautetaan class-attribuutti riippuen child-elementin tyypistä
    setFolderChildClass(type, state) {
        const res = [];
        switch (type) {
            case 1:
                res.push('fa-folder-open');
                break;
            case 2:
                res.push('fa-edit');
                break;
            case 3:
                res.push('fa-file-zip-o');
                break;
            case 4:
                res.push('fa-file-image-o');
                break;
            case 5:
                res.push('fa-external-link');
                break;
            default:
                res.push('fa-cogs');
        }
        switch (state) {
            case 4:
                res.push('ann-rajoitettu');
                break;
            case 8:
                res.push('ann-tarkastus');
                break;
            case 10:
                res.push('ann-valmis');
                break;
            default:
                res.push('ann-luonnos');
        }
        return res;
    }

    // palautetaan class-attribuutti riippuen child-elementin tyypistä
    setFolderChildState(state) {
        switch (state) {
            case 4:
                return 'ann-rajoitettu';
                break;
            case 8:
                return 'ann-tarkastus';
                break;
            case 10:
                return 'ann-valmis';
                break;
            default:
                return 'ann-luonnos';
        }
    }

    // ---

    muutaTilakoodia(tila: number) {
        let teksti: string;
        let kuvake: string;
        switch (tila) {
            case 10:
                teksti = 'Siirretään dokumentti Valmis-tilaan ?<p><br><strong>' + this.curr.title + '</strong></p>';
                kuvake = 'fa fa-check-square-o';
                break;
            case 8:
                teksti = 'Siirretään dokumentti Hyväksyttäväksi (Tarkastuslista) ?<p><br><strong>' + this.curr.title + '</strong></p>';
                kuvake = 'fa fa-thumbs-o-up';
                break;
            case 5:
                teksti = 'Siirretään dokumentti Luonnos-tyyppiseksi ?<p><br><strong>' + this.curr.title + '</strong></p>';
                kuvake = 'fa fa-user-o';
                break;
            case 4:
                teksti = 'Siirretään dokumentti Rajoitetulle näkyvyydelle ?'
                        + '<br><br>Tällöin dokumentti näkyy vain sivuston pääkäyttäjille !'
                        + '<br><br><strong>' + this.curr.title + '</strong>';
                kuvake = 'fa fa-user-secret';
                break;
            default:
                teksti = 'Tuntematon tilakoodi';
                kuvake = 'fa fa-refresh';
        }
        this.confirmationService.confirm({
                message:  teksti,
                header: 'Vahvista muutos',
                icon: kuvake,
                accept: () => {
                    // this.msgs = [{severity:'info', summary:'Confirmed', detail:'You have accepted'}];
                    this.docService.updateState(this.curr.docid, tila)
                    .then(res => {
                        console.log('doc-json-done', res);
                        if (res.success) {
                            this.curr.state = tila;
                            this.curr.mstamp = res.ts;
                            this.curr.mauth = res.modauth;
                            this.modService.getData();
                        }
                        // muutos 2020-02-25 (ei mennä eteenpäin vaan pystään ko.sivulla)
                        // if (tila === 8) {
                        //     this.router.navigateByUrl('/tarkastuslista');
                        // }
                    });
                },
                reject: () => {
                    // this.msgs = [{severity:'info', summary:'Rejected', detail:'You have rejected'}];
                    // console.log('ei-oota-pukkaa');
                }
        });
    }

    // ---

    routeToChild(id) {
        // console.log('route-to-child-start:', id)
        // Todo: pitäisi tarkistaa että args-parametrina tulee docid ja sellainen on olemassa
        // this.router.navigateByUrl('/sivu/' + (id || 0), { skipLocationChange: true });
        this.router.navigateByUrl('/sivu/' + (id || 0), { replaceUrl: true });
    }

    // ---

    rowSelect(idx: number) {
        const found = this.selectedRows.indexOf(idx);
        if (found >= 0) {
           this.selectedRows.splice( found, 1 );
        } else {
            this.selectedRows.push(idx);
        }
    }

    isRowSelected(idx: number) {
        return  (this.selectedRows.includes(idx)) ? 'fa-check-square-o' : 'fa-square-o';
        // if (this.selectedRows.includes(idx)) {
        //     return 'fa-check-square-o';
        // } else {
        //     return 'fa-square-o';
        // }
    }

    // haeSivu(docid) {
    //     console.log('hae-sivu-funktio: avain:', docid);
    //     // this.otsikko = '<p>uusi sivu :' + key + '</p>';
    //     this.ploneContent = '<p>uusi content :' + docid + '</p>';
    //     console.log('pref=', (this.pRef) ? 'LÖYTYY!!!!!!!' : 'eioo');
    //     // this.pRef.nativeElement.innerHTML = 'huu';
    //     //     '<h2 style="color:green">uusi inner-html=</h2>';
    //     // this.pRef.nativeElement.innerHTML = '<p>Huu42</p>';
    // }

    // ---
    // drag-drop modulin antama eventti siitä kun esim. kansio tms. pudotetaan uuteen paikkaansa
    // tässä saadaan Array joka on järjestetty uuteen järjestykseen
    // ja sille täytyy nyt tehdä for-looppi jonka perusteella kaikille listan elementeille
    // täytyy antaa uusi order-no ja se täytyy vielä käydä päivittämässä kantaan
    // ---
    onDrop(dropResult: DropResult) {
        // console.log('on-drop-result:', dropResult);
        this.document.children = this.applyDrag(this.document.children, dropResult);
        // this.ddItems = applyDrag(this.document.children, dropResult);

        // käydään muodostamassa uudet järjestysnumerot "children" array:lle
        // Todo: tällä ei välttämättä tehdä mitään ??  siirretään koko toiminto serverille
        const chMap = this.document.children.reduce((map, obj) => {
            map[obj.id] = obj.title;
            return map;
        }, {});
        // console.log('chMap=', chMap);

        // tehdään Javascript map (Todo: tätä ei kyllä tarvita lainkaan. Voi hävittää)
        const nMap = [];
        for (let i = 0, len = this.document.children.length; i < len; i++) {
            nMap.push({ id: this.document.children[i].id, order: i});
        }
        // console.log('uusi-järjestys:', nMap);

        const teksti = 'Kansion järjetystä on muutettu ! <p><br><strong>Talletetaanko pysyväksi ?</strong></p>';
        const kuvake = 'fa fa-exclamation';
        this.confirmationService.confirm({
                header: 'Vahvista kansion järjestyksen muutos',
                message:  teksti,
                icon: kuvake,
                accept: () => {
                    // console.log('jarjestys-dialog-accept');
                    this.docService.updateSequence(this.curr.docid, nMap)
                    .then(res => {
                        console.log('folder-seq-done', res);
                        // Todo: vas.reunan "tree" täytyisi myös päivittää
                        // ja palata siinä samaan kohtaa mistä alettiin tekemään päivitystä
                    });
                },
                reject: () => {
                    // console.log('jarjestys-kansel');
                    // Todo: seur.eivät toimi joten selvitettävä miten tuo järjestys voidaan palauttaa ????
                    // this.router.navigateByUrl('/etusivu', { skipLocationChange: true });
                    // this.router.navigateByUrl('/sivu/' + this.curr.docid);
                    // this.routeToChild(this.curr.docid);
                }
        });

        this.selectedRows = [];

    }

    // ---

    applyDrag(arr, dragResult) {

        const { removedIndex, addedIndex, payload } = dragResult;
        if (removedIndex === null && addedIndex === null) {
            return arr;
        }

        const result = [...arr];
        // console.log('start-result=', result);

        let itemToAdd = payload;

        if (removedIndex !== null) {
            itemToAdd = result.splice(removedIndex, 1)[0];
        }
        if (addedIndex !== null) {
            result.splice(addedIndex, 0, itemToAdd);
        }

        return result;
    }


    // ---
    // numeroiUudestaan(nArr) {
    //     for (let i = 0, len = nArr.length; i < len; i++) {
    //         const ch = this.getChildById(nArr[i].id);
    //         console.log('order-id', i, '/', ch[0].id);
    //     }
    //     return nArr;
    // }

    // // ---
    // getChildById(id) {
    //     // console.log('haku',id)
    //     return this.document.children.filter(x => x.id === id);
    // }

    // funktio joka tunnistaa välilehtien painamisen
    public annTabChange(evt) {
        // console.log('tab-change-evt');
        // haetaan lukukuittauksille sisältö palvelimelta (=lady-loader)
        if (evt.index && evt.index === 1) {
            this.readers.getData(this.curr.docid);
        }
        if (evt.index && evt.index === 2) {
            this.hist.getData(this.curr.docid);
            // this.readers.getData(this.curr.docid);
        }
    }

    // avataan dokumentti muokkaustilaan (tinyedit)
    // (sallitaan vain sivudokumentille; type=2)
    private avaaMuokkaus() {
        if (this.curr.doctype === 2) {
            const rt = '/muokkaa/' + (this.curr.docid || 0);
            this.router.navigateByUrl(rt, { state: this.curr });
            // this.router.navigateByUrl('/muokkaa/' + (this.curr.docid || 0));
        } else {
            // Todo: muille pitäisi sallia ainakin perustiedojen ylläpito
            // alert('Todo: muille kuin sivudokumenteille koodi vielä vaiheessa');
            this.router.navigateByUrl('/sivu/' + this.curr.docid);
        }
    }

    // avataan dialog-ikkuna jossa voidaan ylläpitää dokumentin metatietoja/yleistietoja
    // (otsikko, kuvaus, tekijä/omistaja ym.)
    private avaaMetatiedot() {
        this.showMetaDlg = true;
    }

    // callback-funktio dialog-ikkunalta (tässä pitää hoitaa päivitys eteenpäin kantaan yms.)
    public updateMeta(args: any) {
        this.showMetaDlg = false;
        // console.log('update-meta-data:', args);
        if (args && args.task && args.task === 'update') {
            this.docService.updateMeta( this.curr.docid, args.data )
                .then((res:any) => {
                    // console.log('update-meta-res=', res);
                    if (args.data && args.data.title) { this.curr.title = args.data.title; }
                    if (args.data && args.data.descr) { this.curr.descr = args.data.descr; }
                    if (args.data && args.data.auth) { this.curr.author = args.data.auth; }
                    return this.modService.getData();
                })
                .then(() => {
                    return this.treeService.getData(0);
                })
                .then(() => {
                    this.treeService.naviToDoc(this.curr.docid);
                });
        }
    }

    // ---

    private avaaNewDoc() {
        console.log('ref=', this.newdocRef);
        this.docService.getParent(this.curr.docid)
            .then(r => {
                this.newdocRef.curr.type = null;
                this.newdocRef.curr.title = null;
                this.newdocRef.curr.descr = null;
                this.newdocRef.curr.auth = this.userService.currUser.uname;
                this.showCreateDlg = true;
            });
    }

    // funktio joka käsittelee "newdoc" komponentin (dialog-ruutu) palauttaman datan
    // ja samalla sulkee ikkunan
    // annCreateDoc(args: any) {
    public createNewDoc(args: any) {
        this.showCreateDlg = false;
        let newTarget = 0;
        if (args && args.req && args.req === 'new') {
            this.docService.createNew(args)
                .then(res => {
                    // console.log('Todo: tarkista miten meni', res);
                    if (res.success && res.insertId) {
                        newTarget = res.insertId;
                    }
                    return this.modService.getData();
                })
                .then(r2 => {
                    return this.treeService.getData(newTarget);
                })
                .then(r3 => {
                    if (newTarget) {
                        this.routeToChild(newTarget);
                    }
                });
        }
    }

    // ---

    public avaaPdf() {
        const rt = '/pdf/' + (this.curr.docid || 0);
        this.router.navigateByUrl(rt, { state: this.curr });
    }

    // ---
    // tehdään HTML -> .docx konversiota joka lataa myös valmiin docx-formaatin itselle
    public avaaDocx() {
        // console.log('avaa-docx', this.userService.currUser);
        // if (this.userService.currUser.scope && this.userService.currUser.scope >= 20) {
        this.docService.muodostaDocx(this.curr.docid)
        .then(res => {
            // console.log('doc-service-done', res);
            if (res.size && res.size>0) {
                this.docService.talletaDocx(res);
            } else {
                console.log('muodosta-docx-paluu-size-on-nolla', res);
                this.router.navigateByUrl('/unknown', { state: this.curr });
            }
        });
        // } else {
        //     alert('TODO: tämä toiminto tulossa ...');
        // }
    }


    // ---

    copyDocs() {

        // kansionäkymässä valittuina olevat dokumentit
        console.log('selected:', this.selectedRows);
        this.sourceWithComm = false;
        this.sourceWithRdr = false;

        // tyhjennetään valinnat ja aletaan sitten katsoa mitä pitäisi siirtää
        // let folderError = false;
        // let foldersFound = false;
        this.sourceNodes = [];

        // yritetään ensin muodostaa valituista dokumenteista lista "sourceNodes"
        // joka sitten voidaan lähettää palvelimelle target-id:n kanssa
        if (this.curr.doctype === 1) {
            for (let i = 0, len = this.selectedRows.length; i < len; i++) {
                const snode = this.document.children[this.selectedRows[i]] || {};
                // if (snode.type === 1) {
                //     folderError = true;
                // } else {
                this.sourceNodes.push({
                    id: snode.id || 0,
                    title: snode.title,
                    type: snode.type || 0,
                    filetype: snode.filetype || null,
                });
                // }
            }
        }
        // ellei löytynyt yhtään ruksia, niin otetaan "current doc" source-listalle
        if (this.sourceNodes.length === 0) {
            this.sourceNodes.push({
                    id: this.curr.docid || 0,
                    title: this.curr.title || '???',
                    type: this.curr.doctype || 0,
                    filetype: this.curr.filetype || null,
                });
        }
        console.log('sourceNodes:', this.sourceNodes);

        // switch (true) {
        // case (folderError) :
        //     alert('Tällä hetkellä kansioiden kopiointi/siirto ei vielä käytössä. Vain dokumentteja.');
        //     break;
        // case (this.sourceNodes.length === 0) :
        //     alert('Valitse ensin dokumentit ruksaamalla kansionäkymän vas.reunasta');
        //     break;
        // default :
            // haetaan palvelimelta uusi hakupuu, jossa mukana vain kansiot
            // (tämä näytetään dialog-ruudulla ja käyttäjä poimii siitä "target-kansion")

        this.treeService.getFolderTree()
            .then(r => {
                // console.log('folder-tree-done:', r);
                // avataan dialog-ikkuna target:n valitsemista varten
                this.showTargetDlg1 = true;
            });
        // }

    }

    // eventti joka saa tiedon mihin kansioon valitut dokumentit on haluttu pudotettavan (copy/move)
    copyTargetSelected(evt) {
        // console.log('target-valittu:', evt);
        this.targetNode.id = (evt.node && evt.node.id) ? evt.node.id : 0;
        this.targetNode.title = (evt.node && evt.node.label) ? evt.node.label : 'Ei ole valittu kohdetta';
        this.targetNode.type = (evt.node && evt.node.data) ? (evt.node.data.type || 0) : 0;
        this.showTargetDlg1 = false;
        this.showTargetDlg2 = true;

    }
    // kopioidaan valitut dokumentit
    copyBtn() {
        this.showTargetDlg2 = false;
        // console.log('with:', this.sourceWithComm, this.sourceWithRdr);
        const args = {
            action: 'copy',
            source: this.sourceNodes || [],
            target: (this.targetNode && this.targetNode.id) ? this.targetNode.id : 0,
            withComm: this.sourceWithComm || false,
            withRdr: this.sourceWithRdr || false,
        };
        // lähetään pyyntö palvelimelle
        this.treeService.copymoveDocs(args)
            .then(res => {
                console.log('copy-ok:', res);
                this.sourceNodes = [];
                this.selectedRows = [];
                return this.modService.getData();
            })
            .then(r2 => {
                return this.treeService.getData('after_copy');
            })
            .then(r3 => {
                if (args.target) {
                    this.router.navigateByUrl('/sivu/' + args.target);
                }
            });
    }

    moveBtn() {
        this.showTargetDlg2 = false;
        const args = {
            action: 'move',
            source: this.sourceNodes || [],
            target: (this.targetNode && this.targetNode.id) ? this.targetNode.id : 0,
            withComm: this.sourceWithComm || false,
            withRdr: this.sourceWithRdr || false,
        };
        // lähetään pyyntö palvelimelle
        this.treeService.copymoveDocs(args)
            .then(res => {
                console.log('move-done:', res);
                this.sourceNodes = [];
                this.selectedRows = [];
                return this.modService.getData();
            })
            .then(r2 => {
                return this.treeService.getData('after_move');
            })
            .then(r3 => {
                if (args.target) {
                    this.router.navigateByUrl('/sivu/' + args.target);
                }
            });
    }

    cancelBtn() {
        this.showTargetDlg2 = false;
        this.sourceNodes = [];
        this.selectedRows = [];
    }

    // ---
    // poistetaan dokumentti
    // ---

    removeSelected() {

        // tyhjennetään valinnat ja aletaan sitten katsoa mitä pitäisi poistaa
        this.sourceNodes = [];

        // Mikäli ei oltu ruksattu mitään erityistä, niin poistetaan "current" ja sen alta lapset
        if (this.selectedRows.length === 0) {
            this.sourceNodes.push({
                id: this.curr.docid || 0,
                type: this.curr.doctype || 0,
                title: (this.curr.doctype===1) ? this.curr.title + ' [kansio]' : this.curr.title,
            });
            // console.log('1-vaihe-nodes', this.sourceNodes);
            if (this.document.children) {
                this.document.children.forEach(snode => {
                    this.sourceNodes.push({
                        id: snode.id || 0,
                        type: snode.type || 0,
                        title: (snode.type===1) ? snode.title + ' [kansio]' : snode.title,
                    });
                });
            };
        } else {
            this.selectedRows.forEach(i => {
                const snode = this.document.children[i] || {};
                // console.log('child.selected:', snode)
                this.sourceNodes.push({
                    id: snode.id || 0,
                    type: snode.type || 0,
                    title: (snode.type===1) ? snode.title + ' [kansio]' : snode.title,
                });
            });
        }
        console.log('sourceNodes:', this.sourceNodes);

        // avataan vahvistusikkuna
        this.showRemoveDlg = true;

    }

    annRemoveDoc(args: any) {
        // console.log('remove-docs-start:', args);
        this.showRemoveDlg = false;
        // let _parent = 0;
        let _parent = (this.curr.docid) ? this.curr.docid : 0;
        if (args && args.req && args.req === 'remove' && this.sourceNodes.length > 0) {
            this.docService.removeDocs(this.sourceNodes, _parent)
            .then(r => {
                // console.log('remove-done:', r)
                // console.log('curr.doc:', this.curr)
                if (r.doclist && r.doclist.length>0 && r.doclist.includes(this.curr.docid)) {
                    // console.log('curr-doc-poistettu:', this.curr.docid)
                    _parent = (this.curr.parent) ? this.curr.parent : 0;
                }
                return this.modService.getData();
            })
            .then(() => {
                return this.treeService.getData(0);
            })
            .then((r) => {
                // console.log('tree-service-data:', r);
                let i = this.selectedRows.length;
                while (i--) {
                    // console.log('splice-doc:', this.selectedRows[i])
                    this.document.children.splice(this.selectedRows[i], 1);
                }
                this.selectedRows = [];
                console.log('navi-to-doc:', _parent)
                // this.treeService.naviToDoc(_parent);
                if (_parent) {
                    this.router.navigateByUrl('/sivu/' + _parent);
                } else {
                    this.router.navigateByUrl('/etusivu/redirect');
                }
            });
        }
    }

    // merkataan ko.sivu etusivuksi
    annMarkEtusivu() {
        // this.showRemoveDlg = false;
        this.confirmationService.confirm({
            message:  'Merkataan tämä sivu etusivuksi kaikille',
            header: 'Vahvista muutos',
            icon: 'fa fa-check-square-o',
            accept: () => {
                this.docService.merkkaaEtusivu()
                    .then(res => {
                        // console.log('etusivu-done', res);
                        this.userService.site.etusivu = this.curr.docid;
                    });
            },
            reject: () => {}
        });
    }

    // ---
    // avataan tällä hetkellä pdf-dokumentti ruudulle
    // Todo: tätä pitäisi laajentaa niin että näyttää muiyakin dokumentteja
    // niin että serveri generoi niistä lennossa .pdf-versiot tms.
    avaaLiitetiedosto(id) {
        // this.visiblePdf = true;
        const rt = '/pdf/' + (id || 0);
        this.router.navigateByUrl(rt, { state: this.curr });
        // this.router.navigateByUrl('/pdf/42', { state: { id: 1 , name: 'nimijuttu' } });
    }

    // ---
    // Todo: tämä vielä keskeneräinen (tullaanko tähän ennen vai jälkeen upload:n)
    doneLiitetiedosto(evt) {
        // console.log('Todo: upload-juttu', evt);
        this.showUploader = false;
        const _task = (evt && evt.task) ? evt.task : 'cancel';
        switch (_task) {
            case 'done':
                if (evt.data && evt.data.success) {
                    this.curr.filename = evt.data.filename || 'unknown';
                    this.curr.filetype = evt.data.filetype || null;
                    this.curr.filesize = evt.data.filesize || 0;
                    this.curr.filesuffix = evt.data.filesuffix || null;
                    this.curr.mauth = evt.data.modauth || null;
                    this.curr.mstamp = evt.data.modts || null;
                    this.modService.getData();
                }
                break;
            case 'cancel':
                this.showUploader = false;
                break;
            default:
                console.log('liite-service-task-unknown?:', evt);
            }
    }

    // seurataan sivun scroll-positiota
    @HostListener('window:scroll', ['$event']) // <- Add scroll listener to window
    scrolled(evt: any): void {
        // console.log('scrolled=', evt);
        // console.log('scrolled=', window.scrollY);
        // this.lastScroll = window.scrollY || 0;
        this.userService.setScroll(this.curr.docid, window.scrollY);
        // console.log('scrolled=', this.userService.lastScroll);
    }

    // palautetaan dokumentin scroll-positio siihen mikä oli aiemmin
    private scrollToPrev(): void {
        // const sp = this.userService.lastScroll.get(this.curr.docid) || 0;
        // console.log('prev-scroll-position=', this.curr.docid, sp);
        // console.log('prev-scroll-position=', this.userService.lastScroll);
        // window.scroll({ top: sp, left: 0 });
        // window.scroll({ top: this.userService.getScroll(this.curr.docid), left: 0 });
    }

}

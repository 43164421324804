
import { Component, AfterViewInit, OnDestroy } from '@angular/core';
import { ElementRef, Renderer2, ViewChild } from '@angular/core';
// import { Subject } from 'rxjs';

import { ScrollPanel } from 'primeng/scrollpanel';

// import { Router, ActivatedRoute } from '@angular/router';
import { Router, Event, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

import { UserService } from './services/user.service';
// import { HakuComponent } from './haku/haku.component';
// import { LeftbarComponent } from './leftbar/leftbar.component';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.styl']
})

export class AppComponent implements AfterViewInit {

    hakuIkkuna = false;
    layoutMode = 'static';
    // darkMenu = true;
    darkMenu = false;

    profileMode = 'popup';
    rotateMenuButton: boolean;
    topbarMenuActive: boolean;
    overlayMenuActive: boolean;
    staticMenuDesktopInactive: boolean;
    staticMenuMobileActive: boolean;
    layoutMenuScroller: HTMLDivElement;
    menuClick: boolean;
    topbarItemClick: boolean;
    activeTopbarItem: any;
    resetMenu: boolean;
    menuHoverActive: boolean;
    grouped = true;

    @ViewChild('layoutMenuScroller', { static: true }) layoutMenuScrollerViewChild: ScrollPanel;

    testhtml = `
<p style="text-align: center; font-size: 15px;">

  <h2>Got questions or need help?</h2>
  <ul>
    <li>eka rivi</li>
    <li>Tokarivi</li>
    <li>Kolmas rivi</li>
  </ul>

  <h2>A simple table to play with</h2>
  <table style="text-align: center;">
    <thead>
      <tr>
        <th>Product</th>
        <th>Cost</th>
        <th>Really?</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>TinyMCE Cloud</td>
        <td>Get started for free</td>
        <td>YES!</td>
      </tr>
      <tr>
        <td>Plupload</td>
        <td>Free</td>
        <td>YES!</td>
      </tr>
    </tbody>
  </table>
`;
    config: any = {
        height: 250,
        theme: 'modern',
        // powerpaste advcode toc tinymcespellchecker a11ychecker mediaembed linkchecker help
        plugins: 'print preview fullpage searchreplace autolink directionality' +
                ' visualblocks visualchars fullscreen image imagetools link media ' +
                ' template codesample table charmap hr pagebreak nonbreaking anchor ' +
                ' insertdatetime advlist lists textcolor wordcount contextmenu colorpicker textpattern',
        toolbar: 'formatselect | bold italic strikethrough forecolor backcolor | link ' +
                ' | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent ' +
                ' | removeformat',
        image_advtab: true,
        imagetools_toolbar: 'rotateleft rotateright | flipv fliph | editimage imageoptions',
        templates: [
            { title: 'Test template 1', content: 'Test 1' },
            { title: 'Test template 2', content: 'Test 2' }
        ],
        content_css: [
            '//fonts.googleapis.com/css?family=Lato:300,300i,400,400i',
            '//www.tinymce.com/css/codepen.min.css'
        ]
    };

    // constructor(public renderer: Renderer2, private haku: HakuComponent) {}
    constructor(
            private router: Router,
            public renderer: Renderer2,
            public userService: UserService,
    ) {
        // router.events
        //   .pipe(filter((routerEvent: Event) => routerEvent instanceof NavigationEnd))
        //   .subscribe((evt) => {
        //         // console.log('huu2:', evt);
        //         window.scrollTo(0, 0);
        //     });
    }

    ngAfterViewInit() {
        console.log('Start: ann2client v.20220310');
        // setTimeout(() => { this.layoutMenuScrollerViewChild.moveBar(); }, 100);
        // console.log('++++');
        // console.log('Start: tämä on app.component after-view-init:', this.router.getCurrentNavigation());
        // console.log('ja sama by window:', window.location.href);
        // this.userService.isAuth('init');
        // Todo: tässä vielä jokin mättää
        this.userService.getAuth('init')
            .then(res => {
                // console.log('app-component-after-view-init-getauth-done', res);
                if (res.success) {
                    // Todo: sessiolla pitäisi olla voimassa viimeinen dokumentti missä käyttäjä on ollut
                    // joten tässä voitaisiin siirtyä sitten sinne
                    // Todo: jotenkin hoidettava se että jos käyttäjä tekee selaimessa Refresh-toiminnon
                } else {
                    this.router.navigateByUrl('/login');
                }
            });

    }

    // ---

    onLayoutClick() {
        if (!this.topbarItemClick) {
            this.activeTopbarItem = null;
            this.topbarMenuActive = false;
        }

        if (!this.menuClick) {
            if (this.isHorizontal() || this.isSlim()) {
                this.resetMenu = true;
            }

            if (this.overlayMenuActive || this.staticMenuMobileActive) {
                this.hideOverlayMenu();
            }

            this.menuHoverActive = false;
        }

        this.topbarItemClick = false;
        this.menuClick = false;
    }

    onMenuButtonClick(event) {
        console.log('onMenuButtonClick-app-component:', event);
        this.menuClick = true;
        this.rotateMenuButton = !this.rotateMenuButton;
        this.topbarMenuActive = false;

        if (this.layoutMode === 'overlay' && !this.isMobile()) {
            this.overlayMenuActive = !this.overlayMenuActive;
        } else {
            if (this.isDesktop()) {
                this.staticMenuDesktopInactive = !this.staticMenuDesktopInactive; } else {
                this.staticMenuMobileActive = !this.staticMenuMobileActive; }
        }

        event.preventDefault();
    }

    // ---

    onMenuClick($event) {
        // console.log('onMenuClick:', event);
        this.menuClick = true;
        this.resetMenu = false;
        // if (!this.isHorizontal()) {
        //     setTimeout(() => {this.layoutMenuScrollerViewChild.moveBar(); }, 450);
        // }
    }

    // ---

    onTopbarMenuButtonClick(event) {
        console.log('top-bar-menu-click-eventti', event);
        this.topbarItemClick = true;
        this.topbarMenuActive = !this.topbarMenuActive;
        this.hideOverlayMenu();
        event.preventDefault();
    }

    avaaHakuIkkuna(args) {
        // console.log('avaa-haku-ikkuna-eventti', args);
        this.hakuIkkuna = true;
        // this.haku.asetaFocus();  // Todo: pitäisi jotenkin saada asetettua focus hakusanakenttään
        // this.haku.teeHaku(args);
    }

    onTopbarItemClick(event, item) {
        this.topbarItemClick = true;

        if (this.activeTopbarItem === item) {
            this.activeTopbarItem = null; } else {
            this.activeTopbarItem = item; }

        event.preventDefault();
    }

    onTopbarSubItemClick(event) {
        event.preventDefault();
    }

    hideOverlayMenu() {
        this.rotateMenuButton = false;
        this.overlayMenuActive = false;
        this.staticMenuMobileActive = false;
    }

    isTablet() {
        const width = window.innerWidth;
        return width <= 1024 && width > 640;
    }

    isDesktop() {
        return window.innerWidth > 1024;
    }

    isMobile() {
        return window.innerWidth <= 640;
    }

    isOverlay() {
        return this.layoutMode === 'overlay';
    }

    isHorizontal() {
        return this.layoutMode === 'horizontal';
    }

    isSlim() {
        return this.layoutMode === 'slim';
    }

    isStatic() {
        return this.layoutMode === 'static';
    }

}


import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
// import { Subscription, Observable } from 'rxjs';

import { DocService } from '../services/doc.service';

@Component({
    selector: 'app-history',
    templateUrl: './history.html',
    styleUrls: ['./history.component.styl']
})

export class HistoryComponent implements OnInit {

    // public history: Observable<any>;
    // public hist: any[];



    constructor(
        public docService: DocService
    ) { }

    ngOnInit() {
        // this.hist = [{ type: 99, auth: 'hessu', ts: '2020-03-20 12:56' }];
        // this.hist.pipe
    }

    getData(docid) {
        console.log('history-get-data-id', docid);
        this.docService.getHistory(docid)
        // .pipe(res => this.hist);
        .then(res => {
            // console.log('hd:', res);
            // if (res.success && res.hist.length > 0) {
            //     this.hist.pipe(res.hist);
            //     console.log('uusi-hist:', this.hist);
            //     for (let i = 0, len = res.hist.length; i < len; i++) {
            //         console.log('löytyy:', res.hist[i].ts);
            //         // this.hist.pipe(res.hist);
            //     }
            // }
        });
    }

    onRowOpen(e) {
        const i = e.index;
        console.log('avataan rivi:', this.docService.hist[i]);
        // this.docService.hist[i].descr = this.testhtml2;
        // this.docService.hist[i].descr = this.testhtml3;
        this.docService.getHistChange(i)
        .then(res => {
            console.log('change:', res);
            this.docService.hist[i].descr = res.diff || 'ei-löydy';
        });
    }

}


import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class KommenttiService {

    public comm: any[] = [];

    constructor(private http: HttpClient) { }

    // ---
    updateComm(args: any): any {
        // console.log('ins-comm-start:', args);
        const _url = '/api/manageComm';
        return this.http
            .post<any>(_url, args)
            .toPromise()
            .then(res => {
                console.log('write-comm-done:', res);
                // this._handler.next(res);
                return res as any;
            });
    }

}

import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
// import { Observable } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';

import { ConfirmationService } from 'primeng/api';
// import { MenuItem } from 'primeng/primeng';

// tätä tarvitaan drag-drop-toimintoihin
import { ContainerComponent, DraggableComponent, DropResult } from 'ngx-smooth-dnd';

// import { AppComponent} from '../app.component';
import { BreadcrumbService } from '../services/breadcrumb.service';
import { DocService } from '../services/doc.service';
import { TreeService } from '../services/tree.service';
import { UserService } from '../services/user.service';

@Component({
    selector: 'app-etusivu',
    templateUrl: './etusivu.component.html',
    styleUrls: ['./etusivu.component.styl']
})

// export class EtusivuComponent implements OnInit {
export class EtusivuComponent implements AfterViewInit {

    // public cols: any[];
    // public events: any[];
    public children: any = [];

    // kansionäkymässä valitut rivit (array)
    public selectedRows: number[] = [];


    constructor(
        // private app: AppComponent,
        private router: Router,
        private route: ActivatedRoute,
        private confirmationService: ConfirmationService,
        public bcService: BreadcrumbService,
        public treeService: TreeService,
        public docService: DocService,
        public userService: UserService,
    ) {
        // Todo: tämän logiikka vielä mietittävä uudestaan, nyt jotain testii
        // this.bcService.setItems([
        //     // { label: 'Dashboard', routerLink: [''] }
        //     { label: 'Etusivu', routerLink: [''] }
        // ]);

    }

    // ngOnInit() {
    ngAfterViewInit() {

        // this.route.paramMap.subscribe(args => {
        //     console.log('etusivu-args:', args);
        //     console.log('etusivu-navi', this.router.url);
        // });

        // Todo: tämä ei tunnu toimivan oikein !!!
        // if (!this.userService.authStatus) {
        //     console.log('jotain vikaa');
        //     this.router.navigateByUrl('/login');
        // }

        // mikäli tullaan url-osoitteella "/etusivu/redirect", niin mennään saman tien
        // etsimään varsinaista etusivua ja sitä kautta SivuComponent-ruudulle
        if (this.router.url === '/etusivu/redirect') {
            if (this.userService.currUser && this.userService.currUser.etusivu) {
                this.router.navigateByUrl('/sivu/' + this.userService.currUser.etusivu);
            }
        } else {
            // muuten pysähdytään ja näytetään ainakin päätason kansiot/dokumentit
            // niin että niitä pystyy järjestelemään uudelleen
            this.docService.getMainFolders('etusivu')
            .then(r => {
                // console.log('haku-done-main-folders:', r);
                this.children = r.docs || [];
            });
        }

    }

    // palautetaan class-attribuutti riippuen child-elementin tyypistä
    setFolderChildClass(type) {
        switch (type) {
            case 1:
                return 'fa-folder';
                break;
            case 2:
                return 'fa-edit';
                break;
            case 3:
                return 'fa-file-zip-o';
                break;
            case 4:
                return 'fa-file-image-o';
                break;
            case 5:
                return 'fa-external-link';
                break;
            default:
                return 'fa-cogs';
        }
    }

    routeToChild(id) {
        // Todo: pitäisi tarkistaa että args-parametrina tulee docid ja sellainen on olemassa
        this.router.navigateByUrl('/sivu/' + (id || 0));
    }

    // ---

    rowSelect(idx: number) {
        const found = this.selectedRows.indexOf(idx);
        if (found >= 0) {
           this.selectedRows.splice( found, 1 );
        } else {
            this.selectedRows.push(idx);
        }
    }

    isRowSelected(idx: number) {
        return  (this.selectedRows.includes(idx)) ? 'fa-check-square-o' : 'fa-square-o';
        // if (this.selectedRows.includes(idx)) {
        //     return 'fa-check-square-o';
        // } else {
        //     return 'fa-square-o';
        // }
    }

    // ---
    // drag-drop modulin antama eventti siitä kun esim. kansio tms. pudotetaan uuteen paikkaansa
    // tässä saadaan Array joka on järjestetty uuteen järjestykseen
    // ja sille täytyy nyt tehdä for-looppi jonka perusteella kaikille listan elementeille
    // täytyy antaa uusi order-no ja se täytyy vielä käydä päivittämässä kantaan
    // (tämä on samanlainen kuin muillakin Folder-tyyppisillä)
    // ---
    onDrop(dropResult: DropResult) {
        // console.log('on-drop-result:', dropResult);
        this.children = this.applyDrag(this.children, dropResult);
        // this.ddItems = applyDrag(this.document.children, dropResult);

        // käydään muodostamassa uudet järjestysnumerot "children" array:lle
        // Todo: tällä ei välttämättä tehdä mitään ??  siirretään koko toiminto serverille
        const chMap = this.children.reduce((map, obj) => {
            map[obj.id] = obj.title;
            return map;
        }, {});
        // console.log('chMap=', chMap);

        const nMap = [];
        for (let i = 0, len = this.children.length; i < len; i++) {
            nMap.push({ id: this.children[i].id, order: i});
        }
        // console.log('uusi-järjestys:', nMap);

        const teksti = 'Kansion järjetystä on muutettu ! <p><br><strong>Talletetaanko pysyväksi ?</strong></p>';
        const kuvake = 'fa fa-exclamation';
        this.confirmationService.confirm({
                header: 'Vahvista järjestyksen muutos',
                message:  teksti,
                icon: kuvake,
                accept: () => {
                    // console.log('jarjestys-dialog-accept');
                    this.docService.updateSequence(0, nMap)
                    .then(res => {
                        console.log('folder-seq-done', res);
                        // päivitetään vas.reunan "hakupuu"
                        this.treeService.getData('etusivu');
                    });
                },
                reject: () => {
                    // console.log('jarjestys-kansel');
                    // Todo: seur.eivät toimi joten selvitettävä miten tuo järjestys voidaan palauttaa ????
                    // this.router.navigateByUrl('/etusivu', { skipLocationChange: true });
                    // this.router.navigateByUrl('/sivu/' + this.curr.docid);
                    // this.routeToChild(this.curr.docid);
                }
        });

        this.selectedRows = [];

    }


// ---

    applyDrag(arr, dragResult) {

        const { removedIndex, addedIndex, payload } = dragResult;
        if (removedIndex === null && addedIndex === null) {
            return arr;
        }

        const result = [...arr];
        // console.log('start-result=', result);

        let itemToAdd = payload;

        if (removedIndex !== null) {
            itemToAdd = result.splice(removedIndex, 1)[0];
        }
        if (addedIndex !== null) {
            result.splice(addedIndex, 0, itemToAdd);
        }

        return result;
    }


}

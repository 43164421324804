
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

// import { RadioButtonModule } from 'primeng/radiobutton';
import { ListboxModule } from 'primeng/listbox';
import { Message } from 'primeng/api';

import { DocService } from '../services/doc.service';
import { UserService } from '../services/user.service';

@Component({
    selector: 'app-newdoc',
    templateUrl: './newdoc.component.html',
    styleUrls: ['./newdoc.component.styl']
})

export class NewdocComponent implements OnInit {

    public types = [
        { type: 1, name: 'Kansio' },
        { type: 2, name: 'Sivu' },
        { type: 3, name: 'Tiedosto' },
        { type: 4, name: 'Kuva' },
        { type: 5, name: 'Linkki' }
      ];

    public selectedType = [];
    public msgs: Message[] = [];

    public curr: any = {
        type: null,
        title: null,
        descr: null,
        auth: null,
    };

    // vlitetään tämän kautta dataa ylöspäin parent-komponentille
    @Output() msgToParent = new EventEmitter<any>();


    constructor(
        public docService: DocService,
        public userService: UserService,
    ) { }


    ngOnInit() {
        this.msgs = [];
     }

    // ---

    typeSelect() {
        if (this.selectedType.length > 1) { this.selectedType.shift(); }
    }

    okBtn(event) {
        this.msgs = [];
        // tarkistetaan että tyyppi on valittu
        if (!this.selectedType.length) {
            this.msgs.push({ severity: 'warn', detail: 'Dokumentin tyyppi puuttuu; valitse listalta.<br>' });
        }
        // dokumentin title on pakollinen
        if (!this.curr.title || this.curr.title.trim().length === 0) {
            this.msgs.push({ severity: 'warn', detail: 'Dokumentin Nimike puuttuu; pakollinen tieto.' });
        }
        // auth on toistaiseksi pakollinen
        if (!this.curr.auth || this.curr.auth.trim().length === 0) {
            this.msgs.push({ severity: 'warn', detail: 'Tekijä tai omistaja puuttuu; pakollinen tieto.' });
        }
        if (this.msgs.length) {
            return;
        } else {
            this.curr.type = this.selectedType[0].type;
            this.msgToParent.emit({ req: 'new', data: this.curr });
        }
    }

    cancelBtn() {
        // this.msgToParent.emit({ req: 'cancel' });
        this.msgs = [];
        this.msgToParent.emit();
    }

}

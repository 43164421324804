
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ElementRef, ViewChild } from '@angular/core';
// import { Directive, Input } from '@angular/core';

import { AppComponent } from '../app.component';
// import { BlogService } from '../demo/service/blogservice';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-haku',
  templateUrl: './haku.component.html',
  styleUrls: ['./haku.component.styl']
})

// export class HakuComponent implements AfterViewInit {
export class HakuComponent implements OnInit {

  @ViewChild('search', { static: true }) searchElement: ElementRef;

  hakutext: string;
  menu1: MenuItem[];

  // constructor(public app: AppComponent, private blogService: BlogService) { }
  constructor(public app: AppComponent) { }

  ngOnInit() {
  // ngAfterViewInit() {

    this.menu1 = [
      { label: 'Dokumentti 1 ........ ', icon: 'fa fa-file-o', routerLink: ['#'] },
      { label: 'Dokumentti 2 ........ ', icon: 'fa fa-file-o', routerLink: ['#'] },
      { label: 'Dokumentti 21 ........ ', icon: 'fa fa-file', routerLink: ['#'] },
      { label: 'Hakutoiminto on vielä ToDo-listalla', icon: 'fa fa-file', routerLink: [''] },
      { label: ' ..... mutta otetaan mallia vanhasta kyllä', icon: 'fa fa-file', routerLink: [''] },
    ];

    // console.log('elem=', this.searchElement);

     // Todo: tämä ei kyllä toimi oikein
    // this.searchElement.nativeElement.focus();

  }

  // onBlurMethod() {
  //   console.log('blur-done');
  // }

  asetaFocus() {
    // this.searchElement.nativeElement.focus();
  }

  teeHaku(args: string) {
    console.log('haku-init=', args);
    this.hakutext = args;
    // this.hakusana.next(args);
  }

}


import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';
import { LoginComponent } from './login/login.component';
import { EtusivuComponent } from './etusivu/etusivu.component';
import { TarklistaComponent } from './tarklista/tarklista.component';
import { NewCommComponent } from './newcomm/newcomm.component';
import { SivuComponent } from './sivu/sivu.component';
import { AnnEditorComponent } from './ann-editor/ann-editor.component';
import { OmasivuComponent } from './omasivu/omasivu.component';
import { ManageComponent } from './manage/manage.component';
import { Pdfviewer2Component } from './pdfviewer2/pdfviewer2.component';
import { Page404Component } from './page404/page404.component';

// import { DashboardDemoComponent } from './demo/view/dashboarddemo.component';
// import { SampleDemoComponent } from './demo/view/sampledemo.component';
// import { DocumentationComponent } from './demo/view/documentation.component';

export const routes: Routes = [

    // Todo: Etusivun reititys on vielä keskeneräinen ja mietinnässä !!!
    { path: '', component: EtusivuComponent },
    { path: 'login', component: LoginComponent },
    { path: 'etusivu', component: EtusivuComponent },
    { path: 'etusivu/redirect', component: EtusivuComponent },
    // { path: 'sivu', component: SivuComponent },
    { path: 'sivu/:id', component: SivuComponent },
    { path: 'sivu/:id/:fragment', component: SivuComponent },
    { path: 'resolveuid/:uid', component: Page404Component },
    { path: 'muokkaa/:id', component: AnnEditorComponent },
    { path: 'pdf/:id', component: Pdfviewer2Component },
    { path: 'tarkastuslista', component: TarklistaComponent },
    { path: 'uusimmatkommentit', component: NewCommComponent },
    { path: 'omasivu', component: OmasivuComponent },
    { path: 'manage', component: ManageComponent },
    { path: 'unknown', component: Page404Component },
    // { path: '**', component: Page404Component },
    { path: '**', component: EtusivuComponent },

];

const routerOptions: ExtraOptions = {
    onSameUrlNavigation: 'reload',
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    // scrollOffset: [0, 0],
    // scrollOffset: [0, 64],
    scrollOffset: [0, 120],
};

// export const AppRoutes: ModuleWithProviders = RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'});
export const AppRoutes: ModuleWithProviders = RouterModule.forRoot(routes, routerOptions);

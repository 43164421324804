import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-sivuref',
//   template: `<h2 [(ngModel)]='otsikko'></h2>`,
//   template: `<h2 [(ngModel)]='otsikko'>Hello {{ otsikko }}!</h2>`,
  template: `{{ otsikko }}`,
  styles: [`h2 { color:red; }`]
})

export class SivucontainerComponent  {
  @Input() otsikko: string;
}


import { Component, Input, OnInit } from '@angular/core';
// import { Observable } from 'rxjs';
// import { Subscription, Observable } from 'rxjs';
import { ConfirmationService } from 'primeng/api';

import { DocService } from '../services/doc.service';
import { UserService } from '../services/user.service';

@Component({
    selector: 'app-readers',
    templateUrl: './readers.component.html',
    styleUrls: ['./readers.component.styl']
})

export class ReadersComponent implements OnInit {

    @Input() docid: any;

    // lukema: Observable<number>;

    // private readers: Observable<any[]>;
    // private rdrCount = 0;

    constructor(
        public confirmationService: ConfirmationService,
        public docService: DocService,
        public userService: UserService,
    ) { }

    ngOnInit() {
        // testataan async-käsittelyä seuraavalla testilla ("this.lukema")
        // this.lukema = Observable.create(observer => {
        //     let value = 0;
        //     const interval = setInterval(() => {
        //         value++;
        //         observer.next(value);
        //     }, 500);
        //     return () => clearInterval(interval);
        // });
        // this.docService._handler.subscribe(res => {
        //     // console.log('subscribe-tarraa-kii:', res);
        //     this.readers = res.rdrList;
        //     this.rdrCount = res.rdrCount;
        // });
    }

    newDoc() {
        console.log('Todo: new-doc-phase');
    }

    getData(id) {
        // console.log('rdr-get-data-request');
        this.docService.getReaders(id);
        // this.docService.getReaders(id).subscribe(res => {
        //     console.log('rdr-done', res);
        //     // console.log('rdrs:', this.readers);
        // });
    }

    kuittaaOma(evt: Event): any {
        // console.log('Todo listalla:', this.docService.currId);
        // toinen parametri: 1=kuittaa_oma
        this.docService.updateKuittaus(this.docService.currId, 1)
        .then(res => {
            // console.log('kuittaa-oma-done', res);
        });
    }

    poistaOmaKuittaus(evt: Event): any {
        // toinen parametri: 2=poista_oma_kuittaus
        this.docService.updateKuittaus(this.docService.currId, 2)
        .then(res => {
            // console.log('poista-oma-done', res);
        });
    }

    // poistetaan kaikki lukukuittaukset (ao.dokumentilta)
    poistaKaikkiKuittaukset() {
        this.confirmationService.confirm({
                message:  'Poistetaan kaikki lukukuittaukset ?',
                header: 'Vahvista poisto',
                icon: 'fa fa-cogs',
                accept: () => {
                    this.docService.updateKuittaus(this.docService.currId, 3)
                    .then(res => {
                        // console.log('poista-kaikki-done', res);
                    });
                },
                reject: () => {
                    // console.log('ei-poisteta');
                }
        });
    }

}


import { Component, Input, OnInit, AfterViewInit, ViewChild } from '@angular/core';
// import { trigger, state, style, transition, animate } from '@angular/animations';
import { Subject } from 'rxjs';

// import { Routes, RouterModule } from '@angular/router';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { Tree } from 'primeng/tree';
import { TreeNode } from 'primeng/api';
import { TreeDragDropService } from 'primeng/api';

import { SivuComponent } from '../sivu/sivu.component';
import { AppComponent } from '../app.component';
import { UserService } from '../services/user.service';
import { TreeService } from '../services/tree.service';
import { DocService } from '../services/doc.service';

@Component({
    selector: 'app-leftbar',
    templateUrl: './leftbar.component.html',
    styleUrls: ['./leftbar.component.styl']
})

export class LeftbarComponent implements OnInit, AfterViewInit {

    @Input() reset: boolean;


    // @ViewChild('expandingTree', { static: false })
    // private expandingTree: Tree;
    // private fixedMenu: any[];

    // näytetäänkö "pääkansiot" ikkunassa jossa niitä voi myös järjestää (drag-drop)
    showMainFolderDlg = false;

    constructor(
        public app: AppComponent,
        public router: Router,
        public sivu: SivuComponent,
        public userService: UserService,
        public treeService: TreeService,
        public docService: DocService,
    ) { }

    ngOnInit() { }

    ngAfterViewInit() {

        // setTimeout(() => { this.app.layoutMenuScrollerViewChild.moveBar(); }, 100);

        // Todo: täytyy tarkistaa auth
        this.treeService.getData('init')
        .then(res => {
            // console.log('ann-tree-done:', res);
        });

    }

    nodeSelect(event) {
        // console.log('node-select-eventti.v.2', event.node);
        // this.router.navigateByUrl('/tarkastuslista');
        // this.sivu.haeSivu(event.node.id);
        this.router.navigateByUrl('/sivu/' + event.node.id);
    }

    nodeUnselect(event) {
        // Todo: tarvitaankohan tätä ?????
        console.log('node-un-select-eventti', event.node);
    }

    // haetaan left-panel-tree uudelleen palvelimelta
    // Todo: tämä vielä keskeneräinen
    refreshTree() {
        this.treeService.getData('refresh')
        .then(res => {
            // console.log('tree-refresh-done', res);
        });

    }

    // ---
    expandAll() {
        // this.files.forEach( node => {
        this.treeService.annMainTree.forEach( node => {
            this.expandRecursive(node, true);
        } );
    }

    // ---
    collapseAll() {
        // this.files.forEach( node => {
        this.treeService.annMainTree.forEach( node => {
            this.expandRecursive(node, false);
        } );
    }

    // ---
    private expandRecursive(node: TreeNode, isExpand: boolean) {
        node.expanded = isExpand;
        if (node.children) {
            node.children.forEach( childNode => {
                this.expandRecursive(childNode, isExpand);
            } );
        }
    }

    // ---
    // tällä yritetty saada treeNode-kohtainen css-luokan dynaaminen määritys
    // (ei käytössä toistaiseksi)
    // getTreeClass(e) {
    //     console.log('class-for:', e);
    // }

    // tämä funktio navigoi etusivulle ja pysähtyy siihen että saadaan lajittelu mukaan
    avaaEtusivu() {
        this.router.navigateByUrl('/etusivu/index');
    }

    avaaTarkLista() {
        this.router.navigateByUrl('/tarkastuslista');
    }

        // Todo: tätä ei tarvita koska koko dialog-ikkuna on toteutettu toisella tavalla
    avaaFolderDlg() {
        // alert('Todo: toiminto puuttuu toistaiseksi');
        this.showMainFolderDlg = true;
        // this.docService.getMainFolders('leftbar')
        //     .then(r => {
        //         console.log('haku-done-main-folders:', r);
        //     });
    }


    avaaKommentit() {
        console.log('Todo: avaa-kommentit-toiminto-puuttuu');
    }

    // tätä ei saatu pelaamaan lainkaan, mutta hoidettu toisella tavalla
    // (tree-olion jok.nodella on data.descr attribuutti jossa tieto on, ja siihen voidaan viitata suoraan templatessa)
    // nodeTooltipOver(args) {
    //     console.log('args', args);
    //     this.currTooltip = 'pitää keksi tälle sisältöö. Testataan kuitenkin vähän pidempää kenttää miten se sitten näkyy. Hui kamalaa.';
    // }


    // Todo: kokeiltu tree-komponentin drag-drop mahdollisuutta, mutta ei käytössä toistaiseksi
    onDrop(evt) {
        console.log('on-drop:', evt);
    }

}
